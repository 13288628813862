import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";
import LoginModal from "./loginmodal";
import { AddModal } from "../components/configurator/RecipeItem";
class Privacy extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className="half-section wow animate__fadeIn  bg-extra-dark-gray-2">
          <div className="container">
            <div
              className="d-flex flex-column flex-md-row justify-content-end extra-small-screen"
              style={{ height: 200 }}
            >
              <div className="w-100 w-md-50 align-self-end">
                <h2 className="alt-font text-white font-weight-500 margin-30px-bottom">{t("title78")}</h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom">
                <span className="text-extra-dark-gray text-large">
                  <br></br>
                  <small>
                    <h6>1. Data protection at a glance</h6>
                    <h6>General Notes</h6>{" "}
                    <p>
                      The following guidelines provide a simple overview of what's going on with your personal data
                      happens when you visit this website. Personal data are all data with which you can be personally
                      identified. Detailed Information on the subject of data protection can be found under this text
                      Privacy Policy.
                    </p>
                    <h6>Data collection on this website</h6>{" "}
                    <h6>Who is responsible for data collection on this website?</h6>{" "}
                    <p>
                      The data processing on this website is carried out by the website operator. Its contact details
                      you can refer to the section &ldquo;Note on the responsible body&rdquo; in this See data
                      protection declaration.
                    </p>{" "}
                    <h6>How do we collect your data?</h6>{" "}
                    <p>
                      On the one hand, your data is collected when you communicate it to us. This can be e.g. deal with
                      data that you enter in a contact form.
                    </p>{" "}
                    <p>
                      Other data is collected automatically or with your consent when you visit the website through our
                      IT systems recorded. These are primarily technical data (e.g. internet browser, operating system
                      or time of the page view). This data is collected automatically as soon as you enter this website.
                    </p>{" "}
                    <h6>What do we use your data for?</h6>{" "}
                    <p>
                      Part of the data is collected to ensure that the website is provided correctly guarantee. Other
                      data can be used to analyze your user behavior.
                    </p>{" "}
                    <h6>What are your rights regarding your data?</h6>{" "}
                    <p>
                      You have the right to free information about origin, recipient and purpose at any time to receive
                      your stored personal data. You also have a right that Request correction or deletion of this data.
                      If you consent to If you have given us data processing, you can revoke this consent at any time
                      for the future withdraw. You also have the right, under certain circumstances, to Request
                      restriction of processing of your personal data. Furthermore stands You have the right to lodge a
                      complaint with the competent supervisory authority.
                    </p>{" "}
                    <p>
                      You can contact us at any time with regard to this and other questions on the subject of data
                      protection turn around.
                    </p>
                    <h6>2. Hosting</h6>
                    <p>We host the content of our website with the following provider:</p>
                    <h6>Amazon Web Services (AWS)</h6>{" "}
                    <p>
                      The provider is Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxembourg
                      (hereinafter AWS).
                    </p>{" "}
                    <p>
                      When you visit our website, your personal data will be stored on AWS servers processed. Personal
                      data can also be sent to the parent company of AWS in be transmitted to the USA. Data transfer to
                      the USA will be based on the EU Standard Contractual Clauses supported. Details can be found here:{" "}
                      <a
                        href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
                      </a>
                      .
                    </p>{" "}
                    <p>
                      For more information, see the AWS Privacy Policy:{" "}
                      <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target="_blank" rel="noopener noreferrer">
                        https://aws.amazon.com/de/privacy/?nc1=f_pr
                      </a>
                      .
                    </p>{" "}
                    <p>
                      AWS is used on the basis of Article 6 (1) (f) GDPR. We have a legitimate interest in the most
                      reliable possible presentation of our website. If a corresponding consent was requested, the
                      processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a GDPR and &sect; 25 para.
                      1 TTDSG, as far as the consent to the storage of cookies or access to information in the End
                      device of the user (e.g. device fingerprinting) within the meaning of the TTDSG. the Consent can
                      be revoked at any time.
                    </p>
                    <h6>Order processing</h6>{" "}
                    <p>
                      We have an order processing contract (AVV) for the use of the above service closed. This is
                      required by data protection law Contract that ensures that the personal data of our website
                      visitors only processed according to our instructions and in compliance with the GDPR.
                    </p>
                    <h6>3. General information and mandatory information</h6>
                    <h6>Privacy Policy</h6>{" "}
                    <p>
                      The operators of these pages take the protection of your personal data very seriously. we treat
                      your personal data confidentially and in accordance with the law data protection regulations and
                      this data protection declaration.
                    </p>{" "}
                    <p>
                      If you use this website, various personal data will be collected. Personal data is data with which
                      you can be personally identified. This data protection declaration explains what data we collect
                      and for what purpose we use them. It also explains how and for what purpose this happens.
                    </p>{" "}
                    <p>
                      We would like to point out that data transmission on the Internet (e.g. with the communication via
                      e-mail) may have security gaps. A complete protection of the Data before access by third parties
                      is not possible.
                    </p>
                    <h6>Note on the responsible body</h6>{" "}
                    <p>The responsible body for data processing on this website is:</p>{" "}
                    <p>Rawbids GmbH, Speditionstrasse 21, 40221 Düsseldorf, Germany</p>
                    <p>
                      Telephone: +49 (0) 1712317438
                      <br />
                      Email: info@commodity-trading-systems.com
                    </p>
                    <p>
                      Responsible body is the natural or legal person who alone or jointly with others about the
                      purposes and means of processing personal data (e.g. names, e-mail addresses, etc.) decides.
                    </p>
                    <h6>Storage period</h6>{" "}
                    <p>
                      Unless a more specific storage period has been specified within this data protection declaration,
                      Your personal data will remain with us until the purpose for data processing not applicable. If
                      you assert a legitimate erasure request or consent revoke your consent to data processing, your
                      data will be deleted unless we have others have lawful grounds for storing your personal
                      information (e.g. tax or commercial retention periods); in the latter case the deletion after
                      these reasons have ceased to exist.
                    </p>
                    <h6>General information on the legal basis for data processing on this website</h6>{" "}
                    <p>
                      If you have consented to data processing, we will process your personal data Data on the basis of
                      Article 6 (1) (a) GDPR or Article 9 (2) (a) GDPR, if special data categories according to Art. 9
                      Para. 1 DSGVO are processed. in the company all one express consent to the transfer of personal
                      data to third countries the data is also processed on the basis of Article 49 (1) (a) GDPR.
                      Provided You in the storage of cookies or in the access to information in your end device (e.g.
                      via device fingerprinting) have consented, the data processing takes place additionally based on
                      &sect; 25 paragraph 1 TTDSG. Consent is at any time revocable. Is your data for the fulfillment of
                      the contract or for the implementation pre-contractual measures are required, we process your data
                      on the basis of Art. 6 Paragraph 1 lit. b GDPR. Furthermore, we process your data insofar as this
                      is necessary to fulfill a legal obligation are required on the basis of Article 6 (1) (c) GDPR.
                      The data processing can also be based on our legitimate interest according to Art. 6 Para. 1 lit.
                      f GDPR. About the legal bases that are relevant in each individual case is informed in the
                      following paragraphs of this data protection declaration.
                    </p>
                    <h6>Note on data transfer to the USA and other third countries</h6>{" "}
                    <p>
                      We use tools from companies based in the USA or others, among others Third countries that are not
                      secure under data protection law. When these tools are active, your personal data are transferred
                      to these third countries and processed there. we point out that in these countries there is no
                      level of data protection comparable to that of the EU can be guaranteed. For example, US companies
                      are required to To release data to security authorities without you as the person concerned
                      objecting to this could take legal action. It cannot therefore be ruled out that US authorities
                      (e.g. secret services) to your data located on US servers Process, evaluate and permanently store
                      for monitoring purposes. We got on this Processing activities have no influence.
                    </p>
                    <h6>Revocation of your consent to data processing</h6>{" "}
                    <p>
                      Many data processing operations are only possible with your express consent possible. You can
                      revoke consent that you have already given at any time. the The legality of the data processing
                      carried out up to the revocation remains from the revocation untouched.
                    </p>
                    <h6>
                      Right to object to data collection in special cases and to direct advertising (Article 21 GDPR)
                    </h6>{" "}
                    <p>
                      IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE AT ANY TIME, FOR
                      REASONS ARISING FROM YOUR PARTICULAR SITUATION, AGAINST THE OBJECT TO THE PROCESSING OF YOUR
                      PERSONAL DATA; THIS ALSO APPLIES TO AN ON PROFILING BASED ON THESE TERMS. THE RELEVANT LEGAL BASIS
                      ON WHICH A PROCESSING IS BASED IN THIS PRIVACY POLICY. IF YOU OBJECT SUBMIT, WE WILL NO LONGER
                      PROCESS YOUR CONCERNED PERSONAL DATA UNLESS WE CAN PROVE COMPREHENSIVE PROTECTIVE REASONS FOR THE
                      PROCESSING THAT YOUR INTERESTS, RIGHTS AND FREEDOMS WILL PREVAIL OR THE PROCESSING IS FOR THE
                      ASSESSMENT, EXERCISE OR DEFENSE OF LEGAL CLAIMS (OBJECTION PURSUANT TO ART. 21 ABS. 1 GDPR).
                    </p>{" "}
                    <p>
                      IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT ADVERTISING, YOU HAVE THAT RIGHT TO OBJECT AT ANY
                      TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR TO ENTER THE PURPOSES OF SUCH
                      ADVERTISING; THIS ALSO APPLIES TO PROFILING, AS FAR AS IT COMES WITH SUCH DIRECT MAIL RELATED. IF
                      YOU OBJECT, YOUR PERSPERSONAL DATA SUBSEQUENTLY NO LONGER USED FOR THE PURPOSE OF DIRECT
                      ADVERTISING (OBJECTION ACCORDING TO ART. 21 PARA. 2 GDPR).
                    </p>
                    <h6>Right of appeal to the competent supervisory authority</h6>{" "}
                    <p>
                      In the event of violations of the GDPR, those affected have the right to lodge a complaint a
                      supervisory authority, in particular in the Member State of your usual residence, their place of
                      work or the location of the alleged violation. That The right to lodge a complaint exists without
                      prejudice to other administrative or judicial ones legal remedies.
                    </p>
                    <h6>Right to data portability</h6>{" "}
                    <p>
                      You have the right to request data that we hold on the basis of your consent or in compliance with
                      a Process the contract automatically, in itself or to a third party in a common, machine-readable
                      format. If you want the direct transfer of the If you request data from another person
                      responsible, this will only be done to the extent that it is technically feasible is.
                    </p>
                    <h6>Information, deletion and correction</h6>{" "}
                    <p>
                      Within the framework of the applicable legal provisions, you have the right to free of charge at
                      any time Information about your stored personal data, its origin and recipient and the purpose of
                      the data processing and, if applicable, a right to rectification or deletion of this Data. You can
                      contact us about this and other questions on the subject of personal data contact us at any time.
                    </p>
                    <h6>Right to restriction of processing</h6>{" "}
                    <p>
                      You have the right to restrict the processing of your personal data to demand. You can contact us
                      at any time for this. The Right to Restriction processing consists of the following cases:
                    </p>{" "}
                    <ul>
                      {" "}
                      <li>
                        If you dispute the accuracy of your personal data stored by us, we usually need time to check
                        this. For the duration of examination, you have the right to restrict the processing of your to
                        request personal data.
                      </li>{" "}
                      <li>
                        If the processing of your personal data was/is unlawful, you can request the restriction of data
                        processing instead of deletion.
                      </li>{" "}
                      <li>
                        When we no longer need your personal information but you want it exercise, defense or
                        establishment of legal claims You have the right to have the processing of your data restricted
                        instead of being erased to request personal data.
                      </li>{" "}
                      <li>
                        If you have lodged an objection in accordance with Art. 21 Para. 1 GDPR, an assessment must be
                        made be made between your interests and ours. As long as it's not clear who interests prevail,
                        you have the right to restrict the processing of your to request personal data.
                      </li>{" "}
                    </ul>{" "}
                    <p>
                      If you have restricted the processing of your personal data, you may this data - apart from their
                      storage - only with your consent or to Establishment, exercise or defense of legal claims or to
                      protect the Rights of another natural or legal person or for reasons of a important public
                      interest of the European Union or a member state are processed.
                    </p>
                    <h6>SSL or TLS encryption</h6>{" "}
                    <p>
                      This site uses confidential information for security reasons and to protect the transmission
                      Content, such as orders or inquiries that you send to us as the site operator, SSL or TLS
                      encryption. You can recognize an encrypted connection by the that the address line of the browser
                      changes from &ldquo;http://&rdquo; on &ldquo;https://&rdquo; changes and the lock symbol in your
                      browser line.
                    </p>{" "}
                    <p>
                      If SSL or TLS encryption is activated, the data you send to us transmitted, not read by third
                      parties.
                    </p>
                    <h6>4. Data collection on this website</h6>
                    <h6>Cookies</h6>{" "}
                    <p>
                      Our website uses so-called &ldquo;cookies&rdquo;. Cookies are small data packages and do not cause
                      any damage to your end device. They will either be temporary for the duration of a session
                      (session cookies) or permanently (permanent cookies) on your Device saved. Session cookies are
                      automatically set after your visit deleted. Permanent cookies remain stored on your end device
                      until you delete them delete it yourself&nbsp;or your web browser will automatically delete it.
                    </p>{" "}
                    <p>
                      In some cases, cookies from third-party companies can also be stored on your device when you enter
                      our site (third-party cookies). These allow us or You the use of certain services of the
                      third-party company (e.g. cookies for processing of payment services).
                    </p>{" "}
                    <p>
                      Cookies have different functions. Numerous cookies are technically necessary because certain
                      Website functions would not work without them (e.g. the shopping cart function or viewing videos).
                      Other cookies are used to monitor user behavior evaluate&nbsp; or display advertising.
                    </p>{" "}
                    <p>
                      Cookies, to carry out the electronic communication process, to provide Certain functions you want
                      (e.g. for the shopping cart function) or are required to optimize the website (e.g. cookies to
                      measure web audience). (necessary cookies) are stored on the basis of Art. 6 Para. 1 lit. f GDPR,
                      provided that no other legal basis is indicated. The website operator has a legitimate interest in
                      the storage of necessary cookies for technically error-free and optimized provision of its
                      services. If consent to the storage of cookies and comparable recognition technologies was
                      queried, the processing takes place exclusively on the basis of this consent (Art. 6 Para. 1 lit.
                      a DSGVO and § 25 Para. 1 TTDSG); the consent can be revoked at any time.
                    </p>{" "}
                    <p>
                      You can set your browser so that you are informed about the setting of cookies and only allow
                      cookies in individual cases, the acceptance of cookies for certain Cases or generally exclude as
                      well as the automatic deletion of cookies when Enable closing of the browser. When deactivating
                      cookies, the Functionality of this website may be limited.
                    </p>{" "}
                    <p>
                      If cookies are used by third-party companies or for analysis purposes, we will inform you inform
                      you about this separately in the context of this data protection declaration and, if necessary, a
                      request consent.
                    </p>
                  </small>
                </span>{" "}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Privacy);
