import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Cert extends Component {
  state = { view: null };
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className="pb-0 pt-0 wow animate__fadeIn bg-light-gray" data-wow-delay="0.2s">
          {/*  <div className="container">
            <div className="row row-cols-12 row-cols-sm-12 align-items-center">
              <div className="col text-center text-sm-left xs-margin-10px-bottom">
                <h4 className="alt-font font-weight-600 text-extra-dark-gray mb-0">
                  Zertifizierungen unserer Produktionsstätten
                </h4>
              </div>
            </div>
          </div> */}
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12  portfolio-colorful ">
                <div
                  className="swiper-container swiper-auto-slide"
                  data-slider-options='{ "loop": true, "slidesPerView": "auto", "navigation": { "nextEl": ".swiper-button-next-nav", "prevEl": ".swiper-button-previous-nav" }, "autoplay": { "delay": 3000, "disableOnInteraction": false }, "spaceBetween": 25, "effect": "slide" }'
                >
                  <div className="swiper-wrapper">
                    {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={"interactive-banners-box-image " + (this.state.view === "haccp" ? "active" : "")}
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              HACCP
                            </h3>

                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "haccp" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={"interactive-banners-box-image " + (this.state.view === "bio" ? "active" : "")}
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              BIO
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "bio" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box ">
                          <div
                            className={"interactive-banners-box-image " + (this.state.view === "22000" ? "active" : "")}
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              ISO 22000
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "22000" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={"interactive-banners-box-image " + (this.state.view === "9001" ? "active" : "")}
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              ISO 9001
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "9001" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={"interactive-banners-box-image " + (this.state.view === "9001" ? "active" : "")}
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              ISO 14001
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "14001" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={"interactive-banners-box-image " + (this.state.view === "9001" ? "active" : "")}
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              ISO 37301
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "37301" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={
                              "interactive-banners-box-image " + (this.state.view === "gmpplus" ? "active" : "")
                            }
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              GMP+
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "gmpplus" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={
                              "interactive-banners-box-image " + (this.state.view === "ifsfood" ? "active" : "")
                            }
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium " />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              IFS FOOD
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "ifsfood" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={
                              "interactive-banners-box-image " + (this.state.view === "ifsbroker" ? "active" : "")
                            }
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              IFS BROKER
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "ifsbroker" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={
                              "interactive-banners-box-image " + (this.state.view === "crefozert" ? "active" : "")
                            }
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              CrefoZert
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "crefozert" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */} {/* start portfolio item */}
                    <div className="swiper-slide">
                      <div className="interactive-banners-style-11">
                        <div className="interactive-banners-box">
                          <div
                            className={
                              "interactive-banners-box-image " + (this.state.view === "greendot" ? "active" : "")
                            }
                          >
                            <div style={{ height: 200, width: 400 }} />
                            <div className="interactive-banners-text-overlay bg-light-gray" style={{ opacity: 1 }} />
                            <div className="opacity-extra-medium" />
                          </div>
                          <div className="interactive-banners-text-content padding-5-rem-lr xl-padding-3-half-rem-lr">
                            <h3 className="alt-font font-weight-600 text-extra-dark-gray text-uppercase margin-5px-bottom">
                              Grüner Punkt
                            </h3>
                            <button
                              className="btn btn-fancy btn-small btn-black  "
                              onClick={() => this.setState({ view: "greendot" })}
                            >
                              {t("button2")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end portfolio item */}
                  </div>
                  {/* start slider navigation */}
                  {/* <div class="swiper-button-next-nav swiper-button-next rounded-circle slider-navigation-style-07"><i class="feather icon-feather-arrow-right"></i></div>
                            <div class="swiper-button-previous-nav swiper-button-prev rounded-circle slider-navigation-style-07"><i class="feather icon-feather-arrow-left"></i></div> */}
                  {/* end slider navigation */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={"section bg-light-gray " + (this.state.view === null ? "d-none" : "d-block")}>
          <div className="container">
            <div className="row margin-4-rem-top overlap-gap-section md-margin-4-rem-top">
              <div className="col-12  ">
                {this.state.view === "9001" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">ISO 9001</h3>

                    <p className="text-extra-dark-gray">{t("text19")}</p>
                  </div>
                ) : null}
                {this.state.view === "22000" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">ISO 22000</h3>

                    <p className="text-extra-dark-gray">{t("text20")}</p>
                  </div>
                ) : null}

                {this.state.view === "14001" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">ISO 14001 - ab 2022</h3>

                    <p className="text-extra-dark-gray">{t("text21")}</p>
                  </div>
                ) : null}

                {this.state.view === "37301" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">ISO 37301 - ab 2023</h3>

                    <p className="text-extra-dark-gray">{t("text22")}</p>
                  </div>
                ) : null}

                {this.state.view === "gmpplus" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">GMP+</h3>

                    <p className="text-extra-dark-gray">{t("text23")}</p>
                  </div>
                ) : null}
                {this.state.view === "ifsfood" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">IFS Food</h3>

                    <p className="text-extra-dark-gray">{t("text25")}</p>
                  </div>
                ) : null}
                {this.state.view === "ifsbroker" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">IFS Broker</h3>

                    <p className="text-extra-dark-gray">{t("text26")}</p>
                  </div>
                ) : null}
                {this.state.view === "crefozert" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">CrefoZert</h3>

                    <p className="text-extra-dark-gray">{t("text59")}</p>
                  </div>
                ) : null}
                {this.state.view === "greendot" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">Grüner Punkt</h3>

                    <p className="text-extra-dark-gray">{t("text60")}</p>
                  </div>
                ) : null}
                {this.state.view === "haccp" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">HACCP</h3>

                    <p className="text-extra-dark-gray">{t("text27")}</p>
                  </div>
                ) : null}
                {this.state.view === "bio" ? (
                  <div>
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray">BIO</h3>

                    <p className="text-extra-dark-gray">
                      {t("text28")}
                      <br></br>
                      <br></br>
                      {t("title37")}
                      <br></br>
                      Gelsenkirchen - DE-ÖKO-006<br></br>
                      Leitmeritz - CZ-BIO-003<br></br>
                      Pirmasens - DE-ÖKO-007
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Cert);
