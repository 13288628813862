import React, { Component } from "react";
import People from "../sections/people";
import Line from "../sections/line";
import JoinTeam from "../sections/joinTeam";
import Jobs from "../sections/jobs";
import UTAlert from "../sections/utAlert";
import { withTranslation } from "react-i18next";
import LoginModal from "./loginmodal";

class RawCaps extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0, showLogin: false };
    this.sectionRef = React.createRef();
    const baseUrl = process.env.PUBLIC_URL+"/images/brands/clearpea/sequence/liquid"

    this.images = [...Array(500)].map((_, i) => baseUrl + (i + 1).toString().padStart(3, '0') + `.webp`); // Pfad zu deinen Bildern
  }

  componentDidMount() {
   this.handleVideoPlay()
  }
  handleVideoPlay = () => {
    setTimeout(() => {
      this.setState({ showContent: true });
    }, 4000);
  }
  handleScroll = () => {
    const section = this.sectionRef.current;
    const sectionRect = section.getBoundingClientRect();
    const viewportHeight = window.innerHeight;


    if (sectionRect.top <= viewportHeight && sectionRect.bottom >= 0) {

      const sectionStart = sectionRect.top - viewportHeight;
      const sectionEnd = sectionRect.bottom;
      const visibleRange = sectionEnd - sectionStart;


      const scrollProgress = viewportHeight - sectionRect.top;
      const fraction = Math.max(0, Math.min(scrollProgress / visibleRange, 1));


      const index = Math.floor(fraction * (this.images.length - 1));


      if (index !== this.state.currentIndex) {
        this.setState({ currentIndex: index });
      }
    }
  };

  render() {
    const { t } = this.props;
    const bgIceTea = process.env.PUBLIC_URL+"/images/brands/clearpea/icetea.jpg"
    const bgCherry = process.env.PUBLIC_URL+"/images/brands/clearpea/cherry.jpg"
    const bgOrange = process.env.PUBLIC_URL+"/images/brands/clearpea/orange.jpg"
    const bgTropical = process.env.PUBLIC_URL+"/images/brands/clearpea/tropical.jpg"
    const bgGreenApple = process.env.PUBLIC_URL+"/images/brands/clearpea/greenapple.jpg"
    return (

      <React.Fragment>
        <LoginModal show={this.state.showLogin} close={() => this.setState({ showLogin: false })} />
        <section className="bg-extra-dark-gray-2 pb-0"
                 style={{ background: "black", paddingTop: 0, position: "relative", height: "70vh" }}>

          {/* Video als Hintergrund */}
          <video
            src={`${process.env.PUBLIC_URL}/images/brands/rawcaps/rcap-square.mp4`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain",
              zIndex: 1,
              backgroundColor: "black",

            }}
            autoPlay
            muted
            playsInline

          />

          {/* Inhalt */}
          <div className="container" style={{
            position: "relative",
            zIndex: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            transition: "opacity 1s",
            marginTop: -110,
            opacity: this.state.showContent ? 1 : 0  // Opacity basierend auf dem State
          }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_light.png`}
              className="mt-0"
              style={{ maxHeight: 60, width: "auto", maxWidth: "50vw" }}
            />
            <h4 className="alt-font-3 font-weight-600 text-white mb-4 mt-10 w-100">
              {t("title349")}
            </h4>
          </div>
        </section>


        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container mt-15">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <h4
                  className="alt-font-3 font-weight-600 text-white w-80 mb-4 text-center"
                  style={{ marginLeft: "10%" }}
                >
                  {t("title350")}
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2 py-10" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container mt-0 px-20">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-6 col-md-3 mb-5 wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-600 text-white " style={{ fontSize: "4rem" }}

                >
                  Vegan
                </span><br />
                <span className="text-gray-2  font-weight-500" style={{ fontSize: "1.6rem" }}>Pure HPMC</span>
              </div>
              <div className="col-6 col-md-3 mb-5 wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-600 text-white " style={{ fontSize: "4rem" }}

                >
                  Grade A
                </span><br />
                <span className="text-gray-2  font-weight-500" style={{ fontSize: "1.6rem" }}>BRC Grading System</span>
              </div>
              <div className="col-6 col-md-3 mb-5 wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-600 text-white " style={{ fontSize: "4rem" }}

                >
                  Certified
                </span><br />
                <span className="text-gray-2  font-weight-500"
                      style={{ fontSize: "1.6rem" }}>Vegan, Kosher, Halal</span>
              </div>
              <div className="col-6 col-md-3 mb-5 wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-600 text-white " style={{ fontSize: "4rem" }}

                >
                  Tested
                </span><br />
                <span className="text-gray-2  font-weight-500" style={{ fontSize: "1.6rem" }}>NJP, Bosch, Sejong</span>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2" style={{ position: "relative", height: "100vh" }}>
          <video
            src={`${process.env.PUBLIC_URL}/images/brands/rawcaps/machine_compressed.mp4`}
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }}
            autoPlay
            muted
            playsInline
            loop
          />

          {/* Dark Overlay */}
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 10, 0.5)",
            zIndex: 1
          }}></div>

          <div className="container"
               style={{ position: "relative", zIndex: 2, height: "100%", display: "flex", alignItems: "center" }}>
            <div className="row">
              <div className="col-12">
                <div className="cont">
                  <h2 className="alt-font font-weight-600 text-white w-95 line-height-60px">
                    Hochwertige HPMC-Kapseln, weltweit geschätzt für Qualität und Preis-Leistung.
                  </h2>
                  <h5 className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-100">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_light.png`}
                      style={{ height: 15, width: "auto", marginTop: 0 }} alt="RawCaps Logo"
                    /> {t("text46")}
                  </h5>
                  <b className="text-white">
                    <i className="fa fa-check text-white mr-1"></i>
                    <span style={{ fontSize: "1.2rem" }}>Allergen-Free</span>
                    <i className="fa fa-check text-white ml-4 mr-1"></i>
                    <span style={{ fontSize: "1.2rem" }}>Vegan</span>
                    <i className="fa fa-check text-white ml-4 mr-1"></i>
                    <span style={{ fontSize: "1.2rem" }}>Non-GMO</span>
                    <i className="fa fa-check text-white ml-4 mr-1"></i>
                    <span style={{ fontSize: "1.2rem" }}>Halal</span>
                    <i className="fa fa-check text-white ml-4 mr-1"></i>
                    <span style={{ fontSize: "1.2rem" }}>Kosher</span>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-8 ">
                <div className="cont"><h2
                  className="alt-font font-weight-600 text-white w-95 line-height-60px "><span className="color-font">{t("title352")}</span>
                </h2><h5
                  className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-75"><img
                  src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_light.png`}

                  style={{ height: 16, width: "auto", marginLeft: 2 }} /> {t("text47")} <br /><br />{t("title337")}
                  <br /><u onClick={() => {
                    this.setState({ showLogin: true });
                  }} className="cursor-pointer">{t("title338")}</u></h5></div>
              </div>
              <div className="col-12 col-xl-4 ">
                <div className="row ml-4">
                  <h2
                    className="alt-font font-weight-600 text-white w-95 line-height-60px ">&nbsp;</h2>
                  <h5
                    className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                    <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;BRC Grade A</h5>
                  <h5
                    className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                    <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;Excellent
                    price-performance
                  </h5> <h5
                  className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                  <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;Reliable quality standard
                </h5> <h5
                  className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                  <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;Odorless & Tasteless
                </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2 pt-0" style={{ backgroundColor: "rgb(2,2,2)" }}>
          <div className="container">
            <div className="overlap-section card specification-card py-0">
              <div
                className="border-radius-6px padding-4-half-rem-tb padding-4-rem-lr sm-no-padding-lr wow animate__pulse"
              >
                <div className="row align-items-center justify-content-center">
                  <div className="col-10 col-xl-8 text-xl-start lg-margin-40px-bottom text-center text-xl-left"><span
                    className="text-extra-medium alt-font text-white">{t("title343")}</span>
                    <h6
                      className="alt-font font-weight-600 text-white letter-spacing-minus-1-half m-0">{t("title344")} {" "}
                      <img
                        src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_light.png`}

                        style={{ height: 16, width: "auto", marginLeft: 2 }} /> {t("title345")}</h6></div>
                  <div className="col-22 col-lg-3 text-center">
                    <a className="btn btn-medium btn-neon-orange btn-fancy btn-round-edge section-link"
                       href={`mailto:info@rawbids.com?subject=Sample Request - RAWCaps&body=${encodeURIComponent(`Sehr geehrte Damen und Herren,

bitte schicken Sie mir ein Sample von RAWCaps an folgende Adresse:

[HIER ADRESSE ANGEBEN]

Mit freundlichen Grüßen,

[IHR NAME]`)}`}>{t("title346")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft" data-wow-delay="0.5s">


                <h4
                  className="alt-font-3 font-weight-600 text-white w-100 mb-4"

                >
                  {t("title347")}
                </h4>

                {/* ------------------------------ */}
                <div className="d-flex flex-column specification-header px-0 mx-0">
                  <div className="d-flex align-items-center justify-content-between my-3 w-100"
                       style={{ marginLeft: 25, lineHeight: 2 }}>
                    <div className="d-flex flex-column">
                      <span className="h3 text-white font-weight-700 mb-0 pb-0"><img
                        src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_light.png`}

                        style={{ height: 20, width: "auto", marginLeft: 2 }} /></span>
                      <div className="font-size-lg text-gray-3">Vegan HPMC Capsules
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card specification-card">


                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Basic Information</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">HS-Code</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">39239000000</div>

                      <div className="col-3 text-white font-weight-500 font-size-lg">Aresenic</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 1 ppm</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Heavy Metals</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 10 ppm</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Residue on ignition</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 3% (transp.); ≤ 5% (transl.); ≤
                        9% (opaque)
                      </div>

                      <div className="col-3 text-white font-weight-500 font-size-lg">Loss on Drying</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 8%</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Disintegration</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 15 minutes</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Total Aerobic Microbial Count</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 1000 CFU/g
                      </div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Total Combined molds and yeasts
                        count
                      </div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 100 CFU/g</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Escherichia Coli / 1g</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Negative</div>


                      <div className="col-3 text-white font-weight-500 font-size-lg">Pseudomonas aeruginosa / 1g</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Negative</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Staphylococcus aureus / 1g</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Negative</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Salmonella species / 10g</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Negative</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Raw Material</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">100% HPMC from wooden pulp</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Certificates</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">GMP, CEDEX, FDA, ISO, BRC, Kosher,
                        Halal
                      </div>
                    </div>
                  </div>
                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Capsule Sizes & Parameters</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row w-100">
                      <table className="w-100 text-center">
                        <th className="text-white font-weight-500 font-size-lg">Size</th>
                        <th className="text-white font-weight-500 font-size-lg">Part</th>
                        <th className="text-white font-weight-500 font-size-lg">Length
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">Wall Thickness
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">Mouth Diameter
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">Lock Length
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">Average Weight
                        </th>
                        <tr>
                          <td colSpan={7} style={{ lineHeight: 1 }}>&nbsp;</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td className="text-white font-weight-500 font-size-lg" rowSpan={2}>000</td>
                          <td>Cap</td>
                          <td>12.95 mm</td>
                          <td>0.105~0.145 mm</td>
                          <td>9.81~9.91 mm</td>
                          <td rowSpan={2}>26.1±0.5 mm</td>

                          <td rowSpan={2}>163±11.0 mm</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td>Body</td>
                          <td>22.2 mm</td>
                          <td>0.105~0.145 mm</td>
                          <td>9.45~9.55 mm</td>
                        </tr>
                        <tr>
                          <td colSpan={7} style={{ lineHeight: 1 }}>&nbsp;</td>
                        </tr>

                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td className="text-white font-weight-500 font-size-lg" rowSpan={2}>00</td>
                          <td>Cap</td>
                          <td>12 mm</td>
                          <td>0.090~0.120 mm</td>
                          <td>8.50~8.60 mm</td>
                          <td rowSpan={2}>25.5±0.5 mm</td>
                          <td rowSpan={2}>130±9.0 mm</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td>Body</td>
                          <td>22.2 mm</td>
                          <td>0.090~0.120 mm</td>
                          <td>8.15~8.25 mm</td>
                        </tr>

                        <tr>
                          <td colSpan={7} style={{ lineHeight: 1 }}>&nbsp;</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td className="text-white font-weight-500 font-size-lg" rowSpan={2}>0</td>
                          <td>Cap</td>
                          <td>11 mm</td>
                          <td>0.085~0.115 mm</td>
                          <td>7.61~7.71 mm</td>
                          <td rowSpan={2}>21.5±0.3 mm</td>
                          <td rowSpan={2}>98±7.0 mm</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td>Body</td>
                          <td>18.6 mm</td>
                          <td>0.085~0.115 mm</td>
                          <td>7.30~7.40 mm</td>
                        </tr>
                        <tr>
                          <td colSpan={7} style={{ lineHeight: 1 }}>&nbsp;</td>
                        </tr>

                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td className="text-white font-weight-500 font-size-lg" rowSpan={2}>1</td>
                          <td>Cap</td>
                          <td>9.8 mm</td>
                          <td>0.085~0.115 mm</td>
                          <td>6.90~7.00 mm</td>
                          <td rowSpan={2}>19.2±0.3 mm</td>
                          <td rowSpan={2}>78±6.0 mm</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.2 }}>
                          <td>Body</td>
                          <td>16.6 mm</td>
                          <td>0.080~0.110 mm</td>
                          <td>6.61~6.69 mm</td>
                        </tr>


                      </table>

                    </div>
                  </div>
                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Capsule Capacity</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row w-100">
                      <table className="w-100 text-center">
                        <th className="text-white font-weight-500 font-size-lg">Size</th>
                        <th className="text-white font-weight-500 font-size-lg">Volume</th>
                        <th className="text-white font-weight-500 font-size-lg">0.6g / ml
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">0.8g / ml
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">1.0g / ml
                        </th>
                        <th className="text-white font-weight-500 font-size-lg">1.2g / ml
                        </th>
                        <tr>
                          <td colSpan={7} style={{ lineHeight: 1 }}>&nbsp;</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.5 }}>
                          <td className="text-white font-weight-500 font-size-lg">000</td>
                          <td>1.370 ml</td>
                          <td>822 mg</td>
                          <td>1096 mg</td>
                          <td>1370 mg</td>
                          <td>1644 mg</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.5 }}>
                          <td className="text-white font-weight-500 font-size-lg">00</td>
                          <td>0.910 ml</td>
                          <td>546 mg</td>
                          <td>728 mg</td>
                          <td>910 mg</td>
                          <td>1092 mg</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.5 }}>
                          <td className="text-white font-weight-500 font-size-lg">0</td>
                          <td>0.680 ml</td>
                          <td>408 mg</td>
                          <td>544 mg</td>
                          <td>680 mg</td>
                          <td>816 mg</td>
                        </tr>
                        <tr className="text-gray-3 font-weight-500 font-size-lg" style={{ lineHeight: 1.5 }}>
                          <td className="text-white font-weight-500 font-size-lg">1</td>
                          <td>0.500 ml</td>
                          <td>300 mg</td>
                          <td>400 mg</td>
                          <td>500 mg</td>
                          <td>600 mg</td>
                        </tr>


                      </table>

                    </div>
                  </div>


                </div>


                {/* ------------------------------ */}


              </div>


            </div>

          </div>
        </section>


        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(RawCaps);
