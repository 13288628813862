import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class HeroImage extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="big-section cover-background">
          <div
            className="opacity-extra-medium-2 bg-dark-slate-blue"
            style={{ opacity: 0.6, backgroundColor: "#151b2a" }}
          />
          <div className="container">
            <div className="row justify-content-center pt-5 pb-5">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 overlap-gap-section pt-5 pb-5">
                <h2
                  className="alt-font text-white font-weight-600  wow animate__fadeIn  text-uppercase mb-3 herotitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title121")} <u>{t("title122")}</u>
                </h2>{" "}
                <h2
                  className="alt-font text-white font-weight-600 margin-45px-bottom md-margin-35px-bottom xs-margin-25px-bottom wow animate__fadeIn  text-uppercase herotitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title123")}
                </h2>
                <h6
                  className="text-white alt-font text-uppercase letter-spacing-1px wow animate__fadeIn herosubtitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title124")}
                  <br />
                  {t("title125")}
                </h6>
                <button
                  className="btn btn-transparent-white btn-lg"
                  type={"button"}
                  onClick={() => this.props.showLogin()}
                >
                  {t("title126")}
                </button>
              </div>
            </div>
          </div>
          <video loop="true" autoPlay controls="" muted className="html-video herovideo" id="video-10"  playsInline>
            <source type="video/mp4" src="images/bgvideo.mp4" />
          </video>
        </section>
      </>
    );
  }
}

export default withTranslation("general")(HeroImage);
