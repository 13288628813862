import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";
import LoginModal from "./loginmodal";
import { AddModal } from "../components/configurator/RecipeItem";
class Imprint extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className="half-section wow animate__fadeIn  bg-extra-dark-gray-2">
          <div className="container">
            <div
              className="d-flex flex-column flex-md-row justify-content-end extra-small-screen"
              style={{ height: 200 }}
            >
              <div className="w-100 w-md-50 align-self-end">
                <h2 className="alt-font text-white font-weight-500 margin-30px-bottom">{t("title48")}</h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom">
                <span className="text-extra-dark-gray text-large">
                  <br></br>
                  <b>Rawbids GmbH</b>
                  <br /> Willy-Brandt-Straße 23, 20457 Hamburg, Germany <br />
                  Amtsgericht Hamburg, HRB 182887
                  <br />
                  Chief Executive Officer: Sebastian Back
                  <br /> <br />
                  info@commodity-trading-systems.com
                  <br /> +49 (0)211 88231618
                  <br />
                  <br />
                </span>{" "}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Imprint);
