import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class People extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <section
        className=""
        style={{
          background: `url('${process.env.PUBLIC_URL}/images/servers.jpg')`,
          minHeight: "100vh",
          backgroundSize: "cover ",
        }}
      >
        <div className="opacity-light bg-medium-slate-blue" />
        <div className="mt-15 d-md-none">
          <br />
          <br />
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 position-relative text-center one-fourth-screen d-flex flex-column justify-content-center sm-h-350px">
            <span className="text-uppercase text-small alt-font letter-spacing-5px text-white d-inline-block margin-20px-bottom font-weight-500 sm-margin-10px-bottom">
              {t("title85")}
            </span>
            <h1 className="alt-font text-white font-weight-600 text-uppercase d-block mb-0 tech">{t("title86")}</h1>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(People);
