import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";

class Dark extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section
          className="p-0 bg-light-gray wow animate__fadeIn"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="container my-25">
            {/*<div className="row equal-cols">
              <div className="col-12 col-lg-6 p-0">
                <div className="cards pb-0 px-0 d-flex flex-column justify-content-between">
                  {" "}
                  <div>
                    {" "}
                    <h2 className="font-weight-700 text-dark cardcontent">{t("title167")}</h2>
                    <p className=" line-height-24 font-weight-500   text-extra-medium text-dark cardcontent">
                      {t("text29")}
                    </p>
                  </div>
                  <video
                    className="mt-10 w-100 cardcontentborder cardvideo flex"
                    style={{ verticalAlign: "bottom" }}
                    src="images/laboratory.mp4"
                    muted
                    autoPlay
                    loop
                    playsInline
                  />
                </div>
              </div>{" "}
              <div className="col-12 col-lg-6  p-0">
                <div className="cards  p-2">
                  <div className="cards h-100 bg-white m-0" style={{ borderRadius: 12 }}>
                    {" "}
                    <h2 className="font-weight-700  text-dark">{t("title168")}</h2>
                    <p className=" line-height-24 font-weight-500 text-extra-medium  text-dark">{t("text18")}</p>
                    <img className="mt-10 w-100 " src={process.env.PUBLIC_URL + "/images/Mockup-1.png"} />
                  </div>{" "}
                </div>
              </div>{" "}
            </div>{" "}*/}
            <div className="row">
              <div className="col-12 p-0">
                <div className="cards">
                  <div className="row">
                    {" "}
                    <div className="col-12 col-lg-6">
                      {" "}
                      <h2 className="font-weight-700  text-dark">{t("title169")}</h2>
                      <p className=" line-height-24 font-weight-500   text-extra-medium text-dark">{t("title170")}</p>
                      <ul className=" line-height-35 font-weight-600  text-extra-medium text-dark">
                        <li>{t("title171")}</li>
                        <li>{t("title172")}</li>
                        <li>{t("title173")}</li>
                        <li>{t("title174")}</li>
                        <li>{t("title175")}</li>
                      </ul>
                    </div>
                    <div className="col-12 col-lg-6">
                      <img className="mt-30" src={process.env.PUBLIC_URL + "/images/elements.png"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="row equal-cols">
              <div className="col-12 col-lg-6 p-0">
                <div className="cards">
                  {" "}
                  <h2 className="font-weight-700  text-dark">{t("title176")}</h2>
                  <p className=" line-height-24 font-weight-500   text-extra-medium text-dark">{t("title177")}</p>
                  <ul className=" line-height-35 font-weight-700  text-extra-medium text-dark">
                    <li>{t("title178")}</li>
                    <li>{t("title179")}</li>
                    <li>{t("title180")}</li>
                    <li>{t("title181")}</li>
                    <li>{t("title182")}</li> <li>{t("title184")}</li>
                    <li>{t("title183")}</li>
                  </ul>
                </div>
              </div>{" "}
              <div className="col-12 col-lg-6 p-0">
                <div className="cards">
                  {" "}
                  <h2 className="font-weight-700  text-dark">{t("title185")}</h2>
                  <p className=" line-height-24 font-weight-500   text-extra-medium text-dark">{t("text31")}</p>{" "}
                </div>
              </div>{" "}
            </div>{" "}
            <div className="row">
              <div className="cards">
                <div className="row">
                  {" "}
                  <div className="col-12">
                    {" "}
                    <h2 className="font-weight-700  text-dark">{t("title186")}</h2>
                    <p className=" line-height-24 font-weight-500   text-extra-medium text-dark mb-5">{t("text32")}</p>
                  </div>{" "}
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title187")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title188")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title189")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title190")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title191")}</span>
                  </div>{" "}
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title192")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title193")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/cross.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title194")}</span>
                  </div>{" "}
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title195")}</span>
                  </div>{" "}
                  <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title196")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title197")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title198")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title199")}</span>
                  </div>{" "}
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title200")}</span>
                  </div>{" "}
                  <div className="col-12 col-md-6 col-lg-3 mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title201")}</span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3  mb-3">
                    <img className="w-15" src={process.env.PUBLIC_URL + "/images/check.png"} />
                    <span className="text-dark ml-4 font-weight-bold">{t("title202")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Dark);
