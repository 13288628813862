import React, { Component } from "react";
import Cert from "../sections/cert";
import { withTranslation } from "react-i18next";

class Certifications extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section
          className="bg-extra-dark-gray"
          data-parallax-background-ratio="0.5"
          style={{
            backgroundImage: "url(\"images/people-background-it.jpg\")",
            paddingTop: "5rem",
            paddingBottom: "5rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            overflow: "hidden",
            // backgroundAttachment: "fixed",
            transitionDuration: "0s"
          }}
        >


          <div className="opacity-extra-medium bg-extra-dark-gray" />
          <div className="container pt-15">
            <div className="row align-items-stretch justify-content-center small-screen pt-15">
              <div className="col-12 col-md-9 pt-15 pb-5">
                <h2
                  className="alt-font text-white font-weight-600  wow animate__fadeIn  text-uppercase mb-3 herotitle2"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title27")}
                </h2>{" "}
                <h6
                  className="text-white alt-font text-uppercase letter-spacing-1px wow animate__fadeIn herosubtitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("text2")}
                </h6>
              </div>
              <div className="col-3">&nbsp;</div>
            </div>
          </div>
        </section>


        <section className="wow animate__fadeIn" style={{ visibility: "visible", animationName: "fadeIn" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center margin-4-half-rem-bottom sm-margin-3-rem-bottom">
                <h2 className="alt-font font-weight-600 text-extra-dark-gray w-100 line-height-60px">
                  {" "}
                  {t("title31")}
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/back.jpg"} />
                <div className="name-and-icon">
                  <h6 className="name-text">Sebastian Back</h6>
                  <a href="https://www.linkedin.com/in/sebastian-back-612117249/"><img src="img/linkedin.png"
                                                                                       className="linkedin-icon" /></a>
                </div>
                <p className="position-text">Chief Executive Officer (CEO)</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/steinebrunner.jpg"} />
                <div className="name-and-icon">
                  <h6 className="name-text">Mark Steinebrunner</h6>
                  <a href="https://www.linkedin.com/in/mark-bennet-steinebrunner-3758a1b7/"><img src="img/linkedin.png"
                                                                                                 className="linkedin-icon" /></a>
                </div>
                <p className="position-text">Head of Supply Chain Management</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/oetjen.jpg"} />
                <div className="name-and-icon">
                  <h6 className="name-text">Yvonne Oetjen</h6>
                  <a href="https://www.linkedin.com/in/yvonne-oetjen-814232113/"><img src="img/linkedin.png"
                                                                                      className="linkedin-icon" /></a>
                </div>
                <p className="position-text">Head of Sales Operations</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/inna.jpg"} />
                <div className="name-and-icon">
                  <h6 className="name-text">Jeerana Inna</h6>
                  <img src="img/linkedin.png" className="linkedin-icon opacity-25" />
                </div>
                <p className="position-text">Head of Quality Management</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/zeller.jpg"} />
                <div className="name-and-icon">
                  <h6 className="name-text">Elisa Zeller</h6>
                  <img src="img/linkedin.png" className="linkedin-icon opacity-25" />
                </div>
                <p className="position-text">Head of Development</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/guenther.jpg"} />
                <div className="name-and-icon">
                  <h6 className="name-text">Jan Günther</h6>
                  <img src="img/linkedin.png" className="linkedin-icon opacity-25" />
                </div>
                <p className="position-text">Backoffice</p>
              </div>
              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/1.jpg"} style={{ filter: "blur(10px)" }} />
                <div className="name-and-icon">
                  <h6 className="name-text" style={{ filter: "blur(10px)" }}>Jeerana Inna</h6>
                  <img src="img/linkedin.png" className="linkedin-icon opacity-25" style={{ filter: "blur(10px)" }} />
                </div>
                <p className="position-text" style={{ filter: "blur(10px)" }}>Head of Quality Management</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4" style={{ position: "relative" }}>
                <img className="peopleimg" src="img/people/2.jpg" style={{ filter: "blur(10px)" }} alt="Person 2" />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "-20%",
                    width: "140%",
                    transform: "translateY(-50%)",
                    textAlign: "center",
                    background: "rgba(255, 255, 255, 0.75)",
                    color: "black",
                    fontSize: "2rem",
                    borderRadius: "10px",
                    padding: "10px 0",
                    zIndex: 1,

                  }}
                  className="name-text"
                >
                  <span style={{ fontSize: "1.4rem", fontWeight: 400 }}>{t("text45")}</span>
                </div>
                <div className="name-and-icon" style={{ position: "relative", zIndex: 2 }}>
                  <h6 className="name-text" style={{ filter: "blur(10px)" }}>Elisa Zeller</h6>
                  <img src="img/linkedin.png" className="linkedin-icon opacity-25" style={{ filter: "blur(10px)" }}
                       alt="LinkedIn" />
                </div>
                <p className="position-text" style={{ filter: "blur(10px)", position: "relative", zIndex: 2 }}>Head of
                  Development</p>
              </div>

              <div className="col-12 col-md-6 col-xl-4 p-3 px-4">
                <img className="peopleimg" src={"img/people/3.jpg"} style={{ filter: "blur(10px)" }} />
                <div className="name-and-icon">
                  <h6 className="name-text" style={{ filter: "blur(10px)" }}>Jan Günther</h6>
                  <img src="img/linkedin.png" className="linkedin-icon opacity-25" style={{ filter: "blur(10px)" }} />
                </div>
                <p className="position-text" style={{ filter: "blur(10px)" }}>Backoffice</p>
              </div>
            </div>



          </div>
        </section>
        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-10 text-end sm-margin-40px-bottom">
                <div className="cont">
                  <h3 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">
                    {t("title28")}
                    <br />
                    {t("title29")}
                  </h3>
                  <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                    <span className="font-weight-700">{t("title30")}</span> {t("text3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section><section><div className="container">
        <div className="row">
          <div className="row">
            <div
              className="col-12 col-xl-7 col-md-8 col-sm-10 text-md-start sm-margin-30px-bottom wow animate__fadeIn"
              data-wow-delay="0.1s"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
            >
              <h6 className="alt-font text-extra-dark-gray font-weight-500 mb-0 md-w-90 sm-w-100">
                {t("title44")}&nbsp;
                <strong className="text-decoration-underline">{t("title45")}</strong>
                {t("title46")}
              </h6>
            </div>
            <div
              className="col-12 col-xl-5 col-md-4 text-center text-md-end wow animate__fadeIn"
              data-wow-delay="0.2s"
              style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeIn" }}
            >
              <button
                className="btn btn-large btn-round-edge btn-transparent-black"
                onClick={() => this.props.show()}
              >
                {t("title47")}
                <span className="bg-orange" />
              </button>
            </div>
          </div>
        </div>
      </div>
      </section>
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Certifications);
