import React, { Component } from "react";

import { withTranslation } from "react-i18next";

class ImageSlider extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className="landing-page-auto-slider bg-white pb-0 d-none" id="templates">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-12 col-xl-6 col-lg-7 col-sm-9 text-center margin-5-rem-bottom lg-margin-3-rem-bottom xs-margin-2-rem-bottom wow animate__fadeIn"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeIn",
                }}
              >
                <h2 className="alt-font font-weight-600 text-extra-dark-gray margin-1-rem-bottom">{t("title203")}</h2>
                <h6 className="alt-font font-weight-600 text-extra-dark-gray margin-1-rem-bottom">{t("title204")}</h6>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="cont marquee">
              <div className="mt-50 header-animation-60">
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/1fae2a52082e3ce27c2d91aa45fb6391.jpeg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/6c575b64b5ba329c73b20c7e2b70a7f7.jpg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/6c575b64b5ba329c73b20c7e2b70a7f7.jpg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/16c7c1b5a04eef20c91d7fc119e5a356.jpg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/083d5f725d5e252e9fe8c0d56e61f976.jpeg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/7709476cc9aee3132f97fadf6aa4b124.jpg"
                />
                <img className="tickerimg" src="https://s3.tradingview.com/wall-of-love/BwkKaurgNWQ.jpg" />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/05a20f0c6d7d8a566fc974d8b4a899b2.jpg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/175a9a0166267a324206bdd5dfecfb3e.jpg"
                />
                <img
                  className="tickerimg"
                  src="https://s3.tradingview.com/wall-of-love/ac610b3a2340dea34978e0bd8e3e838c.jpeg"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(ImageSlider);
