import React, { Component } from 'react';

const images = [
  'url(https://healthyfamilyproject.com/wp-content/uploads/2020/05/Lemons-background.jpg)',
  'url(https://www.ndr.de/ratgeber/kochen/warenkunde/orangen156_v-fullhd.jpg)',
  'url(https://www.getraenke-koenner.de/fileadmin/getraenke-koenner.de/Startseite/Wissen/Cola/gekaufte_Bilder_Cola/hp_subpage_600x394_cola4.jpg)',
  'url(https://www.eismann.de/fileadmin/_processed_/4/3/csm_erdbeeren2_cd5b4d5429.jpg)',
  'url(https://healthyfamilyproject.com/wp-content/uploads/2020/05/Lemons-background.jpg)',
  'url(https://www.ndr.de/ratgeber/kochen/warenkunde/orangen156_v-fullhd.jpg)',
  'url(https://www.getraenke-koenner.de/fileadmin/getraenke-koenner.de/Startseite/Wissen/Cola/gekaufte_Bilder_Cola/hp_subpage_600x394_cola4.jpg)',
  'url(https://www.eismann.de/fileadmin/_processed_/4/3/csm_erdbeeren2_cd5b4d5429.jpg)',
  'url(https://healthyfamilyproject.com/wp-content/uploads/2020/05/Lemons-background.jpg)',
  'url(https://www.ndr.de/ratgeber/kochen/warenkunde/orangen156_v-fullhd.jpg)',
  'url(https://www.getraenke-koenner.de/fileadmin/getraenke-koenner.de/Startseite/Wissen/Cola/gekaufte_Bilder_Cola/hp_subpage_600x394_cola4.jpg)',
  'url(https://www.eismann.de/fileadmin/_processed_/4/3/csm_erdbeeren2_cd5b4d5429.jpg)'
];

const colorNames = [
  'Lemon', 'Orange', 'Cola', 'Strawberry', 'Lemon', 'Orange', 'Cola', 'Strawberry', 'Lemon', 'Orange', 'Cola', 'Strawberry'
];

class ColorWheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentColorIndex: 0,
      rotation: 0,
    };
  }

  componentDidMount() {
    this.colorChangeInterval = setInterval(this.changeColor, 1200); // Slightly faster transition time
  }

  componentWillUnmount() {
    clearInterval(this.colorChangeInterval);
  }

  changeColor = () => {
    this.setState(prevState => ({
      currentColorIndex: (prevState.currentColorIndex + 1) % images.length,
      rotation: prevState.rotation + 30,
    }));
  };

  render() {
    const { currentColorIndex, rotation } = this.state;

    const styles = {
      app: {
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      },
      colorName: {
        marginRight: '20px',
        fontSize: '24px',
        textAlign: 'right',
        color: 'white',
        width: '400px', // Fixed width to avoid shifting
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        animation: 'fadeIn 0.5s ease-in-out'
      },
      wheelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      wheel: {
        width: '800px', // Double the previous width
        height: '800px', // Double the previous height
        borderRadius: '50%',
        position: 'relative',
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out' // Faster transition
      },
      section: {
        width: '100%',
        height: '800px',
        position: 'absolute',
        clip: 'rect(0px, 800px, 800px, 400px)', // Adjusted to match new size
        borderRadius: '50%'
      },
      innerCircle: {
        width: '60%',
        height: '60%',
        backgroundColor: 'black',
        borderRadius: '50%',
        position: 'absolute',
        top: '20%',
        left: '20%'
      },
      '@keyframes fadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
      }
    };

    return (
      <div style={styles.app}>
        <div style={styles.container}>
          <div style={styles.colorName}>
            <h2
              className="alt-font font-weight-600 text-white  line-height-60px "><span className="color-font">{colorNames[currentColorIndex]}</span></h2>
          </div>
          <div style={styles.wheelContainer}>
            <div style={styles.wheel}>
              {images.map((image, index) => (
                <div key={index} style={{ ...styles.section, backgroundImage: image, transform: `rotate(${index * 30}deg)` }}></div>
              ))}
              <div style={styles.innerCircle}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColorWheel;
