import React, { Component } from "react";
import People from "../sections/people";
import Line from "../sections/line";
import JoinTeam from "../sections/joinTeam";
import Jobs from "../sections/jobs";
import UTAlert from "../sections/utAlert";

class Team extends Component {
  state = {};
  render() {
    return (
      // Personen:
      // 4 Bremen
      // 6 Gelsenkirchen
      // 3 Ulm
      // 8 Ultratec
      // 12 Revi
      // ---------------
      // 33
      <React.Fragment>
        <People />

        <JoinTeam />

        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default Team;
