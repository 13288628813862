import React, { Component } from "react";
import Cert from "../sections/cert";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { InlineWidget } from "react-calendly";
class CalendarModal extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.show && (
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            style={{ overflow: "hidden auto", background: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="mfp-container mfp-inline-holder">
              <div className="mfp-content">
                <div className="col-11 col-xl-4 col-lg-6 col-md-8 col-sm-9  position-relative mx-auto bg-white  modal-popup-main padding-4-half-rem-all border-radius-6px sm-padding-2-half-rem-lr">
                  <div className="">
                    <h5 className="alt-font font-weight-600 text-extra-dark-gray line-height-30px text-center">
                      {t("title25")}
                    </h5>
                  </div>{" "}
                  <div className="mb-5 mx-0 px-0">
                    {" "}
                    <InlineWidget url="https://calendly.com/rawbids/30min" />
                  </div>
                  <div className="m-2">
                    <button className="btn btn-small btn-text w-100 mt-2 mb-0 pb-0" type="button">
                      <span className="text-medium-gray" onClick={() => this.props.close()}>
                        {t("title26")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("general")(CalendarModal);
