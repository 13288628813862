import React, { Component } from "react";
import Configurator from "../sections/configurator";
import { withTranslation } from "react-i18next";
import { toAbsoluteUrl } from "../utils/helper";

class Portfolio extends Component {
  state = { view: 1 };
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {" "}
        <section
          className="parallax bg-extra-dark-gray"
          data-parallax-background-ratio="0.5"
          style={{
            backgroundImage:
              'url("https://as1.ftcdn.net/v2/jpg/01/89/20/80/1000_F_189208014_ed1VKi2siOqV3xRzplwCKYEEBKRr2gTX.jpg")',
            backgroundPosition: "50% -123px",
            paddingTop: "5rem",
            paddingBottom: "5rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <div className="opacity-extra-medium bg-extra-dark-gray" />
          <div className="container pt-15">
            <div className="row align-items-stretch justify-content-center  pt-5">
              <div className="col-12 col-md-9 pt-15 pb-5">
                <h2
                  className="alt-font text-white font-weight-600  wow animate__fadeIn  text-uppercase mb-3 herotitle2"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title49")}
                </h2>{" "}
                <h6
                  className="text-white alt-font text-uppercase letter-spacing-1px wow animate__fadeIn herosubtitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title50")}
                  <br />
                  {t("title51")}
                </h6>
              </div>
              <div className="col-3">&nbsp;</div>
            </div>
          </div>
        </section>
        <section id="" className="mb-0 pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 position-relative sm-margin-30px-bottom d-none">
                <video
                  loop
                  controls
                  poster="https://www.westend61.de/images/0000707779pw/portrait-of-smiling-young-business-woman-talking-to-somebody-MFF002642.jpg"
                  className="border-radius-20px justify-content-center align-self-center"
                >
                  <source type="video/mp4" src="images/eikon.mp4" />
                </video>
              </div>
              <div className="col-12 ">
                {" "}
                <div className="cont">
                  {" "}
                  <h3 className="alt-font font-weight-600 text-extra-dark-gray w-95">{t("title52")}</h3>
                  <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                    <span className="font-weight-700">{t("title53")}</span> {t("text5")}
                  </p>
                  <br />
                  <p className="text-extra-medium text-extra-dark-gray font-weight-600 margin-5px-bottom d-none">
                    &nbsp;
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </section>
        <section
          className="wow animate__fadeIn pt-0 pb-0 mt-0 pt-10"
          style={{ visibility: "visible", animationName: "fadeIn", marginTop: 0, paddingTop: 0 }}
        >
          <div className="container">
            <div className="row">
              {/* start blockquote item */}
              <div
                className="col-12 col-md-6 bg-light-gray background-no-repeat background-position-right-bottom padding-40px-lr padding-8-rem-tb lg-padding-2-half-rem-lr wow animate__fadeIn"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeIn",
                }}
              >
                <h5 className="alt-font text-extra-dark-gray font-weight-500">
                  {t("title221")} <br />
                  <b>ISO 22000, HACCP & Organic.</b>
                </h5>
                <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                  <span className="font-weight-700">{t("title53")}</span> {t("text35")}
                </p>
                <a
                  className="btn btn-medium btn-round-edge btn-dark mt-10"
                  href="mailto:info@commodity-trading-systems.com?subject=Certificates%20Request"
                >
                  {t("title222")}
                </a>
              </div>
              <div
                className="col-12 col-md-6 cover-background sm-h-450px xs-h-300px wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/images/certt.jpg")})`,
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeIn",
                }}
              ></div>
              {/* end blockquote item */}
            </div>
          </div>
        </section>
        <section id="about">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 valign">
                  <div className="cont">
                    <h2 className="alt-font font-weight-600 text-extra-dark-gray w-95 line-height-60px">
                      {t("title54")}
                    </h2>
                    <h5 className="mt-5 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block w-75">
                      {t("text6")}
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-xl-5 ">
                  {" "}
                  <div className="row ml-3">
                    <h5 className="mt-5 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block  featurelist">
                      <i aria-hidden="true" className="fas fa-check text-orange d-initial"></i>&nbsp;&nbsp;
                      <div className="d-inline-block">{t("title55")}</div>
                    </h5>{" "}
                    <h5 className="mt-5 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block  featurelist">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title56")}
                    </h5>{" "}
                    <h5 className="mt-5 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block  featurelist">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title57")}
                    </h5>{" "}
                    <h5 className="mt-5 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block  featurelist">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title58")}
                    </h5>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light-gray pt-4 pt-md-15">
          <div className="container">
            <div className="row pb-15">
              <div
                className="col-12 tab-style-06 wow animate__fadeIn "
                data-wow-delay="0.4s"
                style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn" }}
              >
                {/* start tab navigation */}
                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom text-white">
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view === 1 ? "active" : "")}
                      data-bs-toggle="tab"
                      onClick={() => this.setState({ view: 1 })}
                    >
                      Master Spec.
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view === 2 ? "active" : "")}
                      data-bs-toggle="tab"
                      onClick={() => this.setState({ view: 2 })}
                    >
                      {t("title59")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view === 3 ? "active" : "")}
                      data-bs-toggle="tab"
                      onClick={() => this.setState({ view: 3 })}
                    >
                      {t("title60")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view === 4 ? "active" : "")}
                      data-bs-toggle="tab"
                      onClick={() => this.setState({ view: 4 })}
                    >
                      {t("title61")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                </ul>
                {/* end tab navigation */}
                <div className="tab-content bg-light-gray">
                  {/* start tab item */}
                  <div
                    id="masterspec"
                    className={"tab-pane fade in  show cont " + (this.state.view === 1 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <h3 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">{t("title62")}</h3>
                        <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          <span className="font-weight-700">{t("title63")}</span> {t("text7")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* end tab item */}
                  {/* start tab item */}
                  <div
                    id="inhouse"
                    className={"tab-pane fade in  show cont " + (this.state.view === 2 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <h3 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">{t("title64")}</h3>
                        <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          <span className="font-weight-700">{t("title65")}</span> {t("title8")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* end tab item */}
                  {/* start tab item */}
                  <div
                    id="thirdparty"
                    className={"tab-pane fade in  show cont " + (this.state.view === 3 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <h3 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">{t("title66")}</h3>
                        <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          <span className="font-weight-700">{t("title67")}".</span> {t("text9")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* end tab item */}
                  {/* start tab item */}
                  <div id="audits" className={"tab-pane fade in  show cont " + (this.state.view === 4 ? "active" : "")}>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <h3 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">{t("title68")}</h3>
                        <p className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          <span className="font-weight-700">{t("title69")}</span>
                          {t("text10")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* end tab item */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="overlap-section" style={{ marginTop: "-70px" }}>
            <div className="bg-neon-orange border-radius-6px padding-4-half-rem-tb padding-4-rem-lr sm-no-padding-lr wow animate__pulse">
              <div className="row align-items-center justify-content-center">
                <div className="col-10 col-xl-8 text-xl-start lg-margin-40px-bottom text-center text-xl-left">
                  <span className="text-extra-medium alt-font text-white">{t("title70")}</span>
                  <h6 className="alt-font font-weight-600 text-white letter-spacing-minus-1-half m-0">
                    {t("title71")} <br />
                    {t("title72")}
                  </h6>
                </div>

                <div className="col-22 col-lg-3 text-center">
                  <button
                    className="btn btn-medium btn-dark-gray btn-fancy btn-round-edge section-link"
                    onClick={() => this.props.showCalendar()}
                  >
                    {t("title73")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-medium-light-gray margin-6-rem-bottom margin-3-rem-top w-100 h-1px" />
          <div className="row align-items-center justify-content-center mb-5">
            <div
              className="col-12 col-xl-7 col-md-8 col-sm-10 text-md-start sm-margin-30px-bottom wow animate__fadeIn mb-5"
              data-wow-delay="0.1s"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
            >
              <h6 className="alt-font text-extra-dark-gray font-weight-500 mb-0 md-w-90 sm-w-100">
                {t("title74")}&nbsp;
                <strong className="text-decoration-underline">{t("title75")}</strong> {t("title76")}
              </h6>
            </div>
            <div
              className="col-12 col-xl-5 col-md-4 text-center text-md-end wow animate__fadeIn"
              data-wow-delay="0.2s"
              style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeIn" }}
            >
              <button className="btn btn-large btn-round-edge btn-transparent-black" onClick={() => this.props.show()}>
                {t("title77")}
                <span className="bg-orange" />
              </button>
            </div>
          </div>
        </div>
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Portfolio);
