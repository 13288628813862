import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Summary extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-10 col-lg-10 col-md-10 md-margin-7-rem-bottom wow animate__fadeInLeft"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInLeft",
              }}
            >
              <h2 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4"> {t("title165")}</h2>
              <p className="text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                <span className="font-weight-700">{t("title166")}</span> {t("text28")}
              </p>
              <br />
              <div className="cont marquee mt-5 d-none">
                <ul className="mt-50 header-animation-60">
                  {[...Array(5)].map((x) => (
                    <li className="tickerprice" key={x}>
                      <div className="cont">
                        <h6 className="alt-font font-weight-700 text-extra-dark-gray w-95 mb-2">ISO 22000</h6>
                        <p>Food safety standard</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(Summary);
