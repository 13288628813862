import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class LoginModal extends Component {
  state = { company: "", email: "", role: "supplier", view: 1 };

  handleSubmit = async () => {
    fetch(
      "https://eu-central-1.aws.data.mongodb-api.com/app/rawbids-prod-dvvxs/endpoint/registerRequest?name=" +
        this.state.company +
        "&mail=" +
        this.state.email +
        "&role=" +
        this.state.role
    ).then((response) => this.setState({ view: 2 }));
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.show && (
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            style={{ overflow: "hidden auto", background: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="mfp-container mfp-inline-holder">
              {this.state.view === 1 && (
                <>
                  <div className="mfp-content">
                    <div className="col-11 col-xl-4 col-lg-6 col-md-8 col-sm-9  position-relative mx-auto bg-white  modal-popup-main padding-4-half-rem-all border-radius-6px sm-padding-2-half-rem-lr">
                      <div className="">
                        <h5 className="alt-font font-weight-600 text-extra-dark-gray line-height-30px text-center">
                          {t("title17")}
                        </h5>
                      </div>
                      <form className="mx-2">
                        <label className="margin-5px-bottom">
                          {t("title18")} <span className="required-error text-radical-red">*</span>
                        </label>
                        <input
                          className="small-input bg-white margin-15px-bottom required error"
                          type="text"
                          name="company"
                          placeholder="Company Name"
                          onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                          value={this.state.company}
                        />
                        <label className="margin-5px-bottom">
                          {t("title19")} <span className="required-error text-radical-red">*</span>
                        </label>
                        <input
                          className="small-input bg-white margin-15px-bottom required"
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                          value={this.state.email}
                        />
                        <label className="margin-5px-bottom">
                          {t("title20")} <span className="required-error text-radical-red">*</span>
                        </label>
                        <select
                          className={"shipping-accordion"}
                          name={"role"}
                          onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                        >
                          <option value={"supplier"}> {t("title21")}</option>
                          <option value={"customer"}> {t("title22")}</option>
                        </select>
                        <p className="text-small">{t("text1")}</p>
                        <button
                          className="btn btn-medium btn-fancy btn-neon-orange w-100 submit"
                          disabled={
                            this.state.company.length < 3 ||
                            this.state.email.length < 3 ||
                            !this.state.email.includes("@") ||
                            !this.state.email.includes(".")
                          }
                          onClick={() => this.handleSubmit()}
                          type="button"
                        >
                          {t("title23")}
                        </button>{" "}
                        <button className="btn btn-small btn-text w-100 mt-2 mb-0 pb-0" type="button">
                          <span className="text-medium-gray" onClick={() => this.props.close()}>
                            {t("title24")}
                          </span>
                        </button>
                      </form>
                    </div>
                  </div>
                </>
              )}
              {this.state.view === 2 && (
                <>
                  <div className="mfp-content">
                    <div className="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3  position-relative mx-auto bg-white  modal-popup-main padding-4-half-rem-all border-radius-6px sm-padding-2-half-rem-lr">
                      <div className="">
                        <h5 className="alt-font font-weight-600 text-extra-dark-gray line-height-30px text-center">
                          <i className="feather icon-feather-check icon-extra-medium text-neon-orange"></i> Registration
                          Successful
                        </h5>{" "}
                        <p className="text-small text-center">
                          We have received your request. One of our employees will contact you shortly to start the
                          onboarding process. We are delighted that you would like to become part of Rawbids and thank
                          you very much for registering.
                        </p>{" "}
                        <button className="btn btn-small btn-text w-100 mt-2 mb-0 pb-0" type="button">
                          <span className="text-medium-gray" onClick={() => this.props.close()}>
                            {t("title24")}
                          </span>
                        </button>
                      </div>
                    </div>{" "}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("general")(LoginModal);
