import React, { Component } from "react";
import LocationDescriptionPirmasens from "./locationDescriptionPirmasens";
import LocationDescriptionLeitmeritz from "./locationDescriptionLeitmeritz";
import LocationDescriptionBremen from "./locationDescriptionBremen";
import LocationDescriptionGelsenkirchen from "./locationDescriptionGelsenkirchen";
import LocationText1 from "./locationText1";
import LocationText2 from "./locationText2";
import LocationText3 from "./locationText3";
import LocationText4 from "./locationText4";

class Location extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default Location;
