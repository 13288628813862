import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Compostable extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section>
          <div className="row">
            <div className="row align-items-center justify-content-center">
              <div className="col-6">
                <img src="https://novacode.dev/mockup.png" className="w-100" />
              </div>
              <div
                className="col-3 md-margin-7-rem-bottom wow animate__fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft",
                }}
              >
                <h2 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">Lorem Ipsum Dolor.</h2>
                <p className="text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                  <span className="font-weight-700">Est ultricies integer quis auctor elit sed vulputate.</span> Mi sit
                  amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper
                  malesuada proin libero nunc consequat interdum varius sit amet mattis vulputate enim nulla aliquet
                  porttitor lacus luctus accumsan tortor posuere ac ut consequat semper viverra nam libero justo laoreet
                  sit amet cursus sit amet dictum sit amet justo donec enim diam vulputate ut pharetra sit amet aliquam
                  id diam maecenas ultricies mi
                </p>
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Compostable);
