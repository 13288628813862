import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";

class Header extends Component {
  state = { showLanguageSelection: false, showBrandsSelection: false };

  showLanguageSelection = () => {
    this.setState({ showLanguageSelection: true });
  };
  hideLanguageSelection = () => {
    setTimeout(() => {
      this.setState({ showLanguageSelection: false });
    }, 250);
  };
  showBrandsSelection = () => {
    this.setState({ showBrandsSelection: true });
  };
  hideBrandsSelection = () => {
    setTimeout(() => {
      this.setState({ showBrandsSelection: false });
    }, 250);
  };


  setLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };
  getLocalStorageLocales = () => {
    return localStorage.getItem("i18nextLng").split(/-|_/)[0];
  };
  getBrowserLocales = (options = {}) => {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

    if (!browserLocales) {
      return "en";
    }

    return opt.languageCodeOnly ? browserLocales[0].split(/-|_/)[0] : browserLocales[0].trim();
  };

  componentDidMount() {
    console.log("Language is: ", localStorage.getItem("i18nextLng"));
    let query = this.getBrowserLocales({ languageCodeOnly: true });
    let storage = localStorage.getItem("i18nextLng");

    if (!storage) {
      if (query === "de" || query === "en") {
        localStorage.setItem("i18nextLng", query);
        window.location.reload();
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      // https://videos.ctfassets.net/vkizfq3pbtzc/2NnzkZ32NC1T6HqOe6R3MR/e466592d9e71428d184842b7b5003783/Trade_Republic_-_2021_-_30sec_loop_-_V12B_Final_LowQ.mp4
      <React.Fragment>
        <header className="">
          <nav className="navbar navbar-expand-lg navbar-dark bg-transparent header-light fixed-top header-reverse-scroll">
            {/*  <nav className="navbar navbar-expand-lg navbar-dark bg-transparent header-light fixed-top navbar-boxed header-reverse-scroll">*/}
            <div className="container container-xxl nav-header-container">
              <div className="col-6 col-lg-2 mr-auto pl-lg-0">
                <a className="navbar-brand" href="/">
                  <img src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} className="mobile-logo" alt="" />
                  <img src={`${process.env.PUBLIC_URL}/images/logo-light.png`} className="default-logo" alt="" />
                  <img src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} className="alt-logo" alt="" />
                </a>
              </div>
              <div className="col-auto menu-order px-lg-0">
                <button
                  className="navbar-toggler float-right"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                </button>
                <div className=" collapse navbar-collapse justify-content-center" id="navbarNav">
                  <ul className="navbar-nav alt-font">
                    <li className="nav-item dropdown megamenu">
                      <a href="/" className="nav-link futura2">
                        {t("title1")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/quality" className="nav-link futura2">
                        {t("title2")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/technology" className="nav-link futura2">
                        {t("title3")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/mission" className="nav-link futura2">
                        {t("title4")}
                      </a>
                    </li>


                    <li
                      className="nav-item dropdown simple-dropdown"
                      onMouseEnter={() => this.showBrandsSelection()}
                      onMouseLeave={() => this.hideBrandsSelection()}
                    >
                      <span className="nav-link futura2">
                        {t("title224")}
                      </span>


                      <ul
                        className={"dropdown-menu " + (this.state.showBrandsSelection ? "d-block" : "d-none")}
                        role="menu"
                      >
                        <li className="nav-item dropdown">
                          <a href="/brands/clearpea" className="futura2" >
                            <img src={`${process.env.PUBLIC_URL}/images/brands/clearpea_dark.png`}
                                 style={{ height: 20, width: "auto", opacity: 100 }} className="cursor-pointer"/>
                          </a>
                        </li>
                        <li className="nav-item dropdown">
                          <a href="/brands/rawcaps" className="futura2 hover-60" >
                            <img src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_dark.png`}

                                 style={{ height: 20, width: "auto" }} />
                          </a>
                        </li>
                        <li className="nav-item dropdown d-none">
                          <a href="/brands/rawd3" className="futura2 hover-60" >
                            <img src={`${process.env.PUBLIC_URL}/images/brands/rawd3_dark.png`}

                                 style={{ height: 20, width: "auto" }} />
                          </a>
                        </li>
                      </ul>

                    </li>


                    <li className="nav-item dropdown megamenu">
                      <a href="/investor" className="nav-link futura2">
                        {t("title5")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <span onClick={() => this.props.show()} className="nav-link futura2">
                        {t("title16")}
                      </span>
                    </li>
                    <li
                      className="nav-item dropdown simple-dropdown"
                      onMouseEnter={() => this.showLanguageSelection()}
                      onMouseLeave={() => this.hideLanguageSelection()}
                    >
                      <a
                        href="javascript:void(0);"
                        className="nav-link disabled"
                        onClick={() => this.setLanguage("en")}
                      >
                        <img
                          src={"/images/countries_128/" + (this.getLocalStorageLocales() || "en") + ".png"}
                          className="fab"
                          style={{ width: 16 }}
                          data-toggle="dropdown"
                          aria-hidden="true"
                          alt=""
                        ></img>
                      </a>

                      <i className="fa fa-angle-down dropdown-toggle" data-toggle="dropdown" aria-hidden="true" />
                      <ul
                        className={
                          "dropdown-menu " + (this.state.showLanguageSelection === true ? " d-block" : " d-none")
                        }
                        role="menu"
                      >
                        <li className="dropdown ">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("en")}>
                            <img
                              src={"/images/countries_128/en.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title7")}
                          </a>
                        </li>
                        <li className="dropdown ">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("de")}>
                            <img
                              src={"/images/countries_128/de.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title6")}
                          </a>
                        </li>
                        {/*} <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("en")}>
                            <img
                              src={"/images/countries_128/en.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title7")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("fr")}>
                            <img
                              src={"/images/countries_128/fr.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title8")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("es")}>
                            <img
                              src={"/images/countries_128/es.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title9")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("it")}>
                            <img
                              src={"/images/countries_128/it.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title10")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("pt")}>
                            <img
                              src={"/images/countries_128/pt.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title11")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("pl")}>
                            <img
                              src={"/images/countries_128/pl.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title12")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("cs")}>
                            <img
                              src={"/images/countries_128/cs.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title13")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("sv")}>
                            <img
                              src={"/images/countries_128/sv.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title14")}
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("tr")}>
                            <img
                              src={"/images/countries_128/tr.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            {t("title15")}
                          </a>
                        </li>*/}
                      </ul>
                    </li>
                  </ul>
                  {" "}
                  <a
                    href="https://app.rawbids.com/login"
                    className="header-button btn btn-very-small btn-neon-orange btn-round-edge font-weight-600  mb-10 mb-lg-0 ml-2"
                  >
                    {t("title83")}
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {" "}
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Header);
