import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Jobs extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 text-center margin-4-half-rem-bottom sm-margin-3-rem-bottom">
          <span className="alt-font text-extra-medium"> {t("title53")}</span>
          <h3 className="alt-font font-weight-600 text-extra-dark-gray">{t("title54")}</h3>
        </div>

        <div className="col-12 col-lg-10 px-0">
          <div
            className="panel-group accordion-event accordion-style-04"
            id="accordion4"
            data-active-icon="icon-feather-minus"
            data-inactive-icon="icon-feather-plus"
          >
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-1"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title56")}</span>
                    <i className="text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">{t("title57")}</span>
              </div>
              <div id="accordion-style-4-1" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body">{t("text45")}</div>
              </div>
            </div>
            {/* end accordion item */}
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-2"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title58")}</span>
                    <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">Bremen</span>
              </div>
              <div id="accordion-style-4-2" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body">{t("text46")}</div>
              </div>
            </div>
            {/* end accordion item */}
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-3"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title59")}</span>
                    <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">Bremen</span>
              </div>
              <div id="accordion-style-4-3" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body">{t("text47")}</div>
              </div>
            </div>
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-4"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title60")}</span>
                    <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">Pirmasens</span>
              </div>
              <div id="accordion-style-4-4" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body"> {t("text48")}</div>
              </div>
            </div>
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-5"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title61")}</span>
                    <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">Bremen</span>
              </div>
              <div id="accordion-style-4-5" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body">Unterstützung unseres Einkaufs in Bremen.</div>
              </div>
            </div>
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-6"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title62")}</span>
                    <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">Gelsenkirchen</span>
              </div>
              <div id="accordion-style-4-6" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body">
                  Konzepterstellung, Entwicklung und Konstruktoin in Bereichen wie Verpackungs- und Förderanlagen,
                  Handlingsysteme und Produktionsanlagen
                </div>
              </div>
            </div>
            {/* start accordion item */}
            <div className="panel border-color-black-transparent">
              <div className="panel-heading">
                <span className="panel-time font-weight-700 text-extra-dark-gray">{t("title55")}</span>
                <a
                  className="accordion-toggle collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion4"
                  href="#accordion-style-4-7"
                  aria-expanded="false"
                >
                  <div className="panel-title">
                    <span className="text-extra-dark-gray d-inline-block font-weight-700">{t("title63")}</span>
                    <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                  </div>
                </a>
                <span className="panel-speaker">Gelsenkirchen</span>
              </div>
              <div id="accordion-style-4-7" className="panel-collapse collapse" data-parent="#accordion4" style={{}}>
                <div className="panel-body">
                  Eigenverantwortliche Planung der elektro-, mess- und regeltechnischen Ausrüstung zur Optimierung
                  unserer Prozessanlagen und Infrastruktureinrichtungen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("general")(Jobs);
