import React, { Component } from "react";
import Location from "../sections/location";
import { withTranslation } from "react-i18next";

class Production extends Component {
  state = { login: false };
  render() {
    const { t } = this.props;
    return (
      <section
        className=""
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/167676/pexels-photo-167676.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2")',
          height: "100vh",
        }}
      >
        {" "}
        <div className="mfp-container mfp-inline-holder">
          <div className="mfp-content">
            <div className="col-11 col-xl-4 col-lg-6 col-md-8 col-sm-9  position-relative mx-auto bg-white  modal-popup-main padding-4-half-rem-all border-radius-6px sm-padding-2-half-rem-lr">
              <div className="">
                <h5 className="alt-font font-weight-600 text-extra-dark-gray line-height-30px text-center">
                  {t("title79")}
                </h5>
              </div>
              <form className="mx-2">
                <label className="margin-5px-bottom">{t("title80")}</label>
                <input
                  className="small-input bg-white margin-15px-bottom required"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                />
                <label className="margin-5px-bottom">{t("title81")}</label>
                <input
                  className="small-input bg-white margin-15px-bottom required"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                />
                <p className="text-small">{t("title82")}</p>
                <button
                  className="btn btn-medium btn-fancy btn-dark-gray w-100 submit"
                  type="button"
                  onClick={() => this.setState({ login: true })}
                >
                  {t("title83")}
                </button>
                {this.state.login ? <p className={"text-danger text-center"}>{t("title84")}</p> : ""}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(Production);
