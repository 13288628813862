import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Stats extends Component {
  state = { view: 1 };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container-fluid bg-light-gray">
          <div className="row row-cols-1 row-cols-xl-4 row-cols-sm-2 pt-5 pb-2">
            <div
              className="col p-0 overflow-hidden interactive-banners-style-02 border-color-medium-gray lg-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.2s"
            >
              <div className="interactive-banners-sub padding-5-half-rem-lr xl-padding-3-rem-tb xl-padding-3-half-rem-lr  lg-padding-6-half-rem-lr">
                <h3 className="alt-font font-weight-600 w-100 d-block xl-w-100">
                  <span className="text-extra-dark-gray">
                    327
                    <br />
                    <h6>{t("title127")}</h6>
                  </span>
                </h3>
                <span className="interactive-banners-name alt-font text-medium font-weight-500 text-light-brownish-orange d-inline-block text-uppercase letter-spacing-2px margin-25px-bottom">
                  {t("title128")}
                </span>
              </div>
            </div>

            <div
              className="col p-0 overflow-hidden interactive-banners-style-02 border-color-medium-gray lg-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.2s"
            >
              <div className="interactive-banners-sub  padding-5-half-rem-lr xl-padding-3-rem-tb xl-padding-3-half-rem-lr  lg-padding-6-half-rem-lr">
                <h3 className="alt-font font-weight-600 w-100 d-block xl-w-100">
                  <span className="text-extra-dark-gray">
                    115
                    <br />
                    <h6>{t("title129")}</h6>
                  </span>
                </h3>
                <span className="interactive-banners-name alt-font text-medium font-weight-500 text-light-brownish-orange d-inline-block text-uppercase letter-spacing-2px margin-25px-bottom">
                  {t("title130")}
                </span>
              </div>
            </div>

            <div
              className="col p-0 overflow-hidden interactive-banners-style-02  border-color-medium-gray lg-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.2s"
            >
              <div className="interactive-banners-sub  padding-5-half-rem-lr xl-padding-3-rem-tb xl-padding-3-half-rem-lr  lg-padding-6-half-rem-lr">
                <h3 className="alt-font font-weight-600 w-100 d-block xl-w-100">
                  <span className="text-extra-dark-gray">
                    45
                    <br />
                    <h6>{t("title131")}</h6>
                  </span>
                </h3>
                <span className="interactive-banners-name alt-font text-medium font-weight-500 text-light-brownish-orange d-inline-block text-uppercase letter-spacing-2px margin-25px-bottom">
                  {t("title132")}
                </span>
              </div>
            </div>

            <div
              className="col p-0 overflow-hidden interactive-banners-style-02 border-right border-color-medium-gray lg-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.2s"
            >
              <div className="interactive-banners-sub  padding-5-half-rem-lr xl-padding-3-rem-tb xl-padding-3-half-rem-lr  lg-padding-6-half-rem-lr">
                <h3 className="alt-font font-weight-600 w-100 d-block xl-w-100">
                  <span className="text-extra-dark-gray">
                    57
                    <br />
                    <h6>{t("title133")}</h6>
                  </span>
                </h3>
                <span className="interactive-banners-name alt-font text-medium font-weight-500 text-light-brownish-orange d-inline-block text-uppercase letter-spacing-2px margin-25px-bottom">
                  {t("title134")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("general")(Stats);
