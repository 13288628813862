import React, { Component } from "react";
import People from "../sections/people";
import Line from "../sections/line";
import JoinTeam from "../sections/joinTeam";
import Jobs from "../sections/jobs";
import UTAlert from "../sections/utAlert";
import { withTranslation } from "react-i18next";
import LoginModal from "./loginmodal";
import ColorWheel from "../sections/wheel";

class ClearPea extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0, showLogin: false };
    this.sectionRef = React.createRef();
    const baseUrl = process.env.PUBLIC_URL+"/images/brands/clearpea/sequence/liquid"

    this.images = [...Array(500)].map((_, i) => baseUrl + (i + 1).toString().padStart(3, '0') + `.webp`); // Pfad zu deinen Bildern
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const section = this.sectionRef.current;
    const sectionRect = section.getBoundingClientRect();
    const viewportHeight = window.innerHeight;


    if (sectionRect.top <= viewportHeight && sectionRect.bottom >= 0) {

      const sectionStart = sectionRect.top - viewportHeight;
      const sectionEnd = sectionRect.bottom;
      const visibleRange = sectionEnd - sectionStart;


      const scrollProgress = viewportHeight - sectionRect.top;
      const fraction = Math.max(0, Math.min(scrollProgress / visibleRange, 1));


      const index = Math.floor(fraction * (this.images.length - 1));


      if (index !== this.state.currentIndex) {
        this.setState({ currentIndex: index });
      }
    }
  };


  render() {
    const { t } = this.props;
    const bgIceTea = process.env.PUBLIC_URL+"/images/brands/clearpea/icetea.jpg"
    const bgCherry = process.env.PUBLIC_URL+"/images/brands/clearpea/cherry.jpg"
    const bgOrange = process.env.PUBLIC_URL+"/images/brands/clearpea/orange.jpg"
    const bgTropical = process.env.PUBLIC_URL+"/images/brands/clearpea/tropical.jpg"
    const bgGreenApple = process.env.PUBLIC_URL+"/images/brands/clearpea/greenapple.jpg"
    return (

      <React.Fragment>
        <LoginModal show={this.state.showLogin} close={() => this.setState({ showLogin: false })} />
        <section className="bg-extra-dark-gray-2 pb-0" style={{ background: "black" }}>
          <div className="container mt-15">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">
                <img src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`} className="mt-20"

                     style={{ maxHeight: 100, width: "auto", maxWidth: "50vw" }} />
                <h4
                  className="alt-font-3 font-weight-600 text-white w-80 mb-4 text-center"
                  style={{ marginLeft: "10%" }}
                >
                  Purely Pea, Perfectly Clear.
                </h4>

              </div>
            </div>
          </div>
        </section>
        <video
          src={`${process.env.PUBLIC_URL}/images/brands/clearpea/wave.mp4`}
          style={{ height: "auto", width: "100vw", marginTop: -200 }}
          autoPlay
          loop
          muted
          playsInline
        >
          {t("title228")}
        </video>
        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container mt-15">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <h4
                  className="alt-font-3 font-weight-600 text-white w-80 mb-4 text-center"
                  style={{ marginLeft: "10%" }}
                >
                  {t("title229")}
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2 py-10" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container mt-0 px-20">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-6 col-md-3 mb-5 wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-600 text-white " style={{ fontSize: "4rem" }}

                >
                  107 <small style={{ fontSize: 24 }}>kcal</small>
                </span><br />
                <span className="text-gray-2  font-weight-500" style={{ fontSize: "1.6rem" }}>{t("title330")} 30g<sup
                  style={{ fontSize: 12 }}>*</sup></span>
              </div>
              <div className="col-6 col-md-3 mb-5  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-500 text-white " style={{ fontSize: "4rem" }}

                >
               77%
                </span><br />
                <span className="text-gray-2 font-weight-500" style={{ fontSize: "1.6rem" }}>Protein<sup
                  style={{ fontSize: 12 }}>*</sup></span>
              </div>
              <div className="col-6 col-md-3 mb-5  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-500 text-white " style={{ fontSize: "4rem" }}

                >
               0%
                </span><br />
                <span className="text-gray-2 font-weight-500" style={{ fontSize: "1.6rem" }}>{t("title331")}</span>
              </div>
              <div className="col-6 col-md-3 mb-5  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <span
                  className="alt-font-3 font-weight-500 text-white " style={{ fontSize: "4rem" }}

                >
               0%
                </span><br />
                <span className="text-gray-2 font-weight-500" style={{ fontSize: "1.6rem" }}>{t("title332")}</span>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div ref={this.sectionRef} style={{ height: "100vh", position: "relative" }}>
            {this.images.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Frame ${index}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: index === this.state.currentIndex ? "block" : "none",
                  objectFit: "cover"
                }}
              />
            ))}
            {/* Text-Container hinzufügen */}
            <div className="container-xxl centered-container">

              <div className="row">
                <div className="col-12 col-xl-8">
<div className="cont">
                  <h1
                    className="alt-font font-weight-600 text-white w-100 line-height-70px ">Natural flavour,<br /><span
                    className="color-font">clearly refreshing.</span></h1>
                  <h5
                    className="mt-5 text-extra-large-2 text-white font-weight-400 margin-5px-bottom d-block">{t("title333") + " "}
                    <img
                      src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                      style={{ height: 24, width: "auto", marginLeft: 2 }} />, {t("text37")}<br /><img
                      src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                      style={{ height: 24, width: "auto", marginLeft: 2 }} /> {t("text38")}<br /><br />{t("text39")}<br /><br /><img
                      src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                      style={{ height: 24, width: "auto", marginLeft: 2 }} /> {t("text40")}<br />{t("text41")}<br /><br />
                  </h5><b
                  className="text-white"><i
                  className="fa fa-check text-white mr-1"></i> <span style={{ fontSize: "1.2rem" }}>Cola</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span style={{ fontSize: "1.2rem" }}>Cherry</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Tropical</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Peach Iced Tea</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Green Apple</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Orange</span></b></div>

              </div>
              </div>

            </div>
          </div>
        </section>

        <section style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container-xxl">
            <div className="row">
              <div className="col-12 col-xl-8 ">
                <div className="cont"><h2
                  className="alt-font font-weight-600 text-white  line-height-60px "><span className="color-font">Pure Protein, Pure Potential.</span>
                </h2><h5
                  className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-100">{t("text42")} <img
                    src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                    style={{ height: 24, width: "auto", marginLeft: 2 }} /> {t("text43")} <img
                    src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                    style={{ height: 24, width: "auto", marginLeft: 2 }} /> {t("text44")}<br /><br />{t("title337")}
                  <br /><u onClick={() => {
                    this.setState({ showLogin: true });
                  }} className="cursor-pointer">{t("title338")}</u></h5></div>
              </div>
              <div className="col-12 col-xl-4 ">
                <div className="row ml-4">
                  <h2
                    className="alt-font font-weight-600 text-white w-95 line-height-60px ">&nbsp;</h2>
                  <h5
                    className="mt-3 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                    <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;{t("title339")}</h5>
                  <h5
                    className="mt-3 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                    <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;{t("title340")}
                  </h5> <h5
                  className="mt-3 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                  <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;{t("title341")}</h5> <h5
                  className="mt-3 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-90 featurelist">
                  <i aria-hidden="true" className="fas fa-check text-white"></i>&nbsp;&nbsp;{t("title342")}
                </h5>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="cont">
                  <video src={`${process.env.PUBLIC_URL}/images/brands/clearpea/cp_promo.mp4`} poster={`${process.env.PUBLIC_URL}/images/brands/clearpea/poster.jpg`} width={"100%"} height={"auto"} controls={true} style={{borderRadius: 10}}/>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft" data-wow-delay="0.5s">


                <h4
                  className="alt-font-3 font-weight-600 text-white w-100 mb-4"

                >
                  {t("title347")}
                </h4>

                {/* ------------------------------ */}
                <div className="d-flex flex-column specification-header px-0 mx-0">
                  <div className="d-flex align-items-center justify-content-between my-3 w-100"
                       style={{ marginLeft: 25, lineHeight: 2 }}>
                    <div className="d-flex flex-column">
                      <span className="h3 text-white font-weight-700 mb-0 pb-0"><img
                        src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                        style={{ height: 24, width: "auto", marginLeft: 2 }} /></span>
                      <div className="font-size-lg text-gray-3">85% Protein, 80% Peptides; Pisum sativum Linn;
                        Seed
                      </div>
                    </div>

                  </div>
                </div>

                <div className="card specification-card " style={{
                  position: "relative",
                  overflow: "hidden"
                }}>
                  <div className="card-blur">
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Basic Information</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">CAS Number</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">fG34k9kz9g</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Origin Country</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Zylandiatonia</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Article Number</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">78654bb12a</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Organic</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Blorbgliata</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Category</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Xyzzylongterm</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">HS Code</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">1234 5678 90 1234</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Composition</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Plumbusonation</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Extraction Solvent</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Gloop, Zorp, Blip,
                          Snorfla
                        </div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Color</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Glorbiano</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Density</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">987 - 654 kg/m3</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Physical & Chemical Parameters</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Appearance</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Frobnicatelyso</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Ash</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 12345%</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Limits</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Qwopylonger</div>
                        <div the="col-3 text-white font-weight-500 font-size-lg">Loss on Drying</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 905%</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Odor</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Blurghishness</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Particle Size</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">42% through 999 mesh
                        </div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Purity</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">>57% above</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Specific Rotation</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Wibbletonia</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">pH</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">6.78 approximately</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Labels & Standards</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">CITES</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Gliptonous</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">ECHA</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">Friptoniously</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Halal</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">12345678</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Hazard Material</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">98765432</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Kosher</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">6243121</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Novel Food</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">678902134</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Packaging</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">246809753</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Vegan</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">135798246</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Vegetarian</div>
                        <div className="col-3 text-success-2 font-weight-500 font-size-lg">0246861357</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Grade</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Cosmetic Grade</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Zilcheryestian</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Feed Grade</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Nadalyonsious</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Food Grade</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Blorbsncity</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Industrial Grade</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Snorflietyus</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Medicine Grade</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Glooplyishious</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Pharmaceutical Grade
                        </div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Zorptilblion</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">USP Grade</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Plumbuslyerous</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Contaminants</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Aflatoxins</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">&lt; 10 ppb</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Benzopyrene</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 10 ppb</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Max. Allowed ETO</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 0.1 ppm</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">PAH 4</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 50 ppb</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Active Substances</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Protein</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">85%</div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Peptides</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">80%</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Allergens</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">
                          <div className="badge badge-dark p-1 px-2">Pea</div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Additional Information</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Storage Conditions</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Cool, Dry Place</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Shelf Life</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">24 Months</div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-white font-weight-500 font-size-lg">Temperature</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">15-25°C</div>
                        <div className="col-3 text-white font-weight-500 font-size-lg">Humidity</div>
                        <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Below 60%</div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="row">
                      <div className="col-12 text-white h4 mb-4">Specification</div>
                    </div>
                    <div className="container" style={{ lineHeight: 1.9 }}>
                      <div className="row">
                        <span style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px"
                        }}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                            alt="PDF Icon"
                            style={{ width: 24, marginBottom: 5 }}
                          />
                          <div style={{ fontSize: ".9rem", color: "white" }}>Cola</div>
                        </span>
                        <span style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px"
                        }}>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                            alt="PDF Icon"
                            style={{ width: 24, marginBottom: 5 }}
                          />
                          <div style={{ fontSize: ".9rem", color: "white" }}>Green Apple</div>
                        </span>
                        <span style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px"
                        }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                            alt="PDF Icon"
                            style={{ width: 24, marginBottom: 5 }}
                          />
                          <div style={{ fontSize: ".9rem", color: "white" }}>Cherry</div>
                        </span>
                        <span style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px"
                        }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                            alt="PDF Icon"
                            style={{ width: 24, marginBottom: 5 }}
                          />
                          <div style={{ fontSize: ".9rem", color: "white" }}>Orange</div>
                        </span>
                        <span style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px"
                        }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                            alt="PDF Icon"
                            style={{ width: 24, marginBottom: 5 }}
                          />
                          <div style={{ fontSize: ".9rem", color: "white" }}>Ice Tea Peach</div>
                        </span>
                        <span style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px"
                        }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                            alt="PDF Icon"
                            style={{ width: 24, marginBottom: 5 }}
                          />
                          <div style={{ fontSize: ".9rem", color: "white" }}>Tropical</div>
                        </span>
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-medium btn-fancy btn-neon-orange btn-round-edge card-button"
                          onClick={() => {
                            this.setState({ showLogin: true });
                          }}>Register now!
                  </button>
                </div>


                {/* ------------------------------ */}


              </div>


              <div className="w-100 d-block text-center mt-10"><sup
                style={{ fontSize: 12 }}>*</sup>{t("title348")}
              </div>

            </div>


          </div>

        </section>


        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(ClearPea);
