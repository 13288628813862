import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class FinalSummary extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <section className="bg-white">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-10 col-lg-10 col-md-10 md-margin-7-rem-bottom wow animate__fadeInLeft"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInLeft",
              }}
            >
              {" "}
              <p className="text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block text-center">
                <span className="font-weight-700">{t("title206")}</span>
              </p>
              <h4
                className="alt-font-2 font-weight-600 text-extra-dark-gray w-80 mb-4 text-center"
                style={{ marginLeft: "10%" }}
              >
                {t("text34")}
              </h4>
              <button
                className="btn btn-dark btn-lg text-center mx-auto d-block mt-5"
                type={"button"}
                onClick={() => this.props.showLogin()}
              >
                Start now
              </button>
              <img src={process.env.PUBLIC_URL + "/images/display.png"} className="w-100  pt-5" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(FinalSummary);
