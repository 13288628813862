import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class JoinTeam extends Component {
  state = { view: 1, view2: 1 };
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="bg-extra-dark-gray-2">
          <div className="container mt-15">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">
                <p className="text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block text-center">
                  <span className="font-weight-700">{t("title87")}</span>
                </p>
                <h4
                  className="alt-font-3 font-weight-600 text-white w-80 mb-4 text-center"
                  style={{ marginLeft: "10%" }}
                >
                  {t("text11")}
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section
          className=" wow animate__fadeIn bg-extra-dark-gray-2 mt-0 pt-0"
          style={{
            visibility: "visible",
            animationName: "fadeIn",
          }}
        >
          <div className="container">
            <div className="row d-none">
              <div
                className="col-12 text-center overlap-section wow animate__zoomIn"
                style={{
                  visibility: "visible",
                  animationName: "zoomIn",
                }}
              ></div>
              <video
                className="welcome-video"
                src="https://static.tradingview.com/static/bundles/chart-big.5ddfd85030f4ba213936.mp4"
                muted
                autoPlay
                loop
                playsInline
              />
            </div>
            <div className="row text-center">
              <img src={process.env.PUBLIC_URL + "/images/Mockup-1.png"} />
            </div>
            <p className={"text-center"}>{t("title88")}</p>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2 pt-4 pt-md-15 pb-0">
          <div className="container">
            <div className="row pb-15">
              <div
                className="col-12 tab-style-06 wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn" }}
              >
                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom text-white">
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view2 === 1 ? "active" : "")}
                      data-bs-toggle="tab"
                      style={{ color: "#ffffff" }}
                      onClick={() => this.setState({ view2: 1 })}
                    >
                      {t("title89")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view2 === 2 ? "active" : "")}
                      data-bs-toggle="tab"
                      style={{ color: "#ffffff" }}
                      onClick={() => this.setState({ view2: 2 })}
                    >
                      {t("title90")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view2 === 3 ? "active" : "")}
                      data-bs-toggle="tab"
                      style={{ color: "#ffffff" }}
                      onClick={() => this.setState({ view2: 3 })}
                    >
                      {t("title91")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link " + (this.state.view2 === 4 ? "active" : "")}
                      data-bs-toggle="tab"
                      style={{ color: "#ffffff" }}
                      onClick={() => this.setState({ view2: 4 })}
                    >
                      {t("title92")}
                    </a>
                    <span className="tab-border bg-gradient-light-purple-light-orange" />
                  </li>
                </ul>
                <div className="tab-content bg-extra-dark-gray-2">
                  <div
                    id="planning-tab"
                    className={"tab-pane fade in  show cont " + (this.state.view2 === 1 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <div className="cont">
                          <h3 className="alt-font font-weight-600 text-white w-95 mb-4">{t("title93")}</h3>
                          <p className="text-extra-medium text-white font-weight-500 margin-5px-bottom d-block">
                            <span className="font-weight-700">{t("title94")}</span>
                            {t("text12")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="research-tab"
                    className={"tab-pane fade in  show cont " + (this.state.view2 === 2 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <div className="cont">
                          <h3 className="alt-font font-weight-600 text-white w-95 mb-4">{t("title95")}</h3>
                          <p className="text-extra-medium text-white font-weight-500 margin-5px-bottom d-block">
                            <span className="font-weight-700">{t("title96")}</span>
                            {t("text13")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="target-tab"
                    className={"tab-pane fade in  show cont " + (this.state.view2 === 3 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <div className="cont">
                          <h3 className="alt-font font-weight-600 text-white w-95 mb-4">{t("title97")}</h3>
                          <p className="text-extra-medium text-white font-weight-500 margin-5px-bottom d-block">
                            <span className="font-weight-700">{t("title98")}</span>
                            {t("text14")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="campaign-tab"
                    className={"tab-pane fade in  show cont " + (this.state.view2 === 4 ? "active" : "")}
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-md-8 offset-md-2 text-end sm-margin-40px-bottom">
                        <div className="cont">
                          <h3 className="alt-font font-weight-600 text-white w-95 mb-4">{t("title99")}</h3>
                          <p className="text-extra-medium text-white font-weight-500 margin-5px-bottom d-block">
                            <span className="font-weight-700">{t("title100")}</span> {t("text15")}
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            </div>
          </div>
        </section>
        <section className="overflow-visible bg-extra-dark-gray-2 mt-0 pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-7 col-lg-8 col-md-9 text-center margin-8-half-rem-top margin-5-half-rem-bottom md-margin-4-half-rem-bottom md-margin-7-rem-top wow animate__fadeIn">
                <span className="alt-font font-weight-500 text-medium text-neon-orange text-uppercase letter-spacing-1px d-block margin-25px-bottom">
                  {t("title101")}
                </span>
                <h4 className="alt-font font-weight-500 text-white margin-5-half-rem-bottom lg-margin-4-half-rem-bottom letter-spacing-minus-1px w-90 xl-w-100 xs-margin-3-half-rem-bottom">
                  {t("title102")}
                </h4>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2 " style={{ background: "#16171e" }}>
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom" />
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin text-white">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-medium">
                      {t("title103")}
                    </span>
                    <p>{t("title104")}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2 " style={{ background: "#16171e" }}>
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom" />
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin text-white">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-medium">
                      {t("title105")}
                    </span>
                    <p>{t("title106")}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2 " style={{ background: "#16171e" }}>
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom" />
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin text-white">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-medium">
                      {t("title107")}
                    </span>
                    <p>{t("title108")}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2 " style={{ background: "#16171e" }}>
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom" />
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin text-white">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-medium">
                      {t("title109")}
                    </span>
                    <p>{t("title110")}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2 " style={{ background: "#16171e" }}>
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom" />
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin text-white">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-medium">
                      {t("title111")}
                    </span>
                    <p>{t("title112")}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2 " style={{ background: "#16171e" }}>
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom" />
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin text-white">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-medium">
                      {t("title113")}
                    </span>
                    <p>{t("title114")}</p>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </section>

        <section id="reading" className="p-0 wow animate__fadeIn">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 col-xl-6 col-lg-5 cover-background md-h-550px sm-h-400px xs-h-300px wow animate__fadeIn"
                style={{
                  background: `url('${process.env.PUBLIC_URL}/images/code.jpg')`,

                  backgroundSize: "cover ",
                }}
              ></div>
              <div
                className="col-12 col-xl-6 col-lg-7 padding-9-half-rem-tb padding-10-half-rem-lr bg-extra-dark-gray-2 xl-padding-2-rem-all  px-5 wow animate__fadeIn"
                data-wow-delay="0.3s"
              >
                <span className="alt-font font-weight-500 text-medium text-neon-orange text-uppercase letter-spacing-1px d-block margin-30px-bottom xs-margin-20px-bottom">
                  {t("title115")}
                </span>
                <h4 className="alt-font font-weight-500 text-white margin-5-half-rem-bottom lg-margin-4-half-rem-bottom letter-spacing-minus-1px w-90 xl-w-100 xs-margin-3-half-rem-bottom">
                  {t("title116")}
                </h4>
                <div
                  className="panel-group accordion-event accordion-style-05"
                  id="accordion1"
                  data-active-icon="fa-angle-down"
                  data-inactive-icon="fa-angle-right"
                >
                  <div className="panel cursorhand">
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 1 ? "active-accordion" : "")
                      }
                    >
                      <span className="accordion-toggle ml-0 " onClick={() => this.setState({ view: 1 })}>
                        <div className="panel-title">
                          <span className="alt-font text-white d-inline-block font-weight-700 ">{t("title117")}</span>
                          <i className="indicator fas fa-angle-down text-white icon-extra-small"></i>
                        </div>
                      </span>
                    </div>
                    <div
                      id="collapseOne"
                      className={"panel-collapse collapse " + (this.state.view === 1 ? "show" : "")}
                    >
                      <div className="panel-body text-white pl-4">{t("title118")}</div>
                    </div>
                  </div>

                  <div className="panel cursorhand">
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 2 ? "active-accordion" : "")
                      }
                    >
                      <span className="accordion-toggle ml-0" onClick={() => this.setState({ view: 2 })}>
                        <div className="panel-title">
                          <span className="alt-font text-white d-inline-block font-weight-700">{t("title119")}</span>
                          <i className="indicator fas fa-angle-down text-white icon-extra-small"></i>
                        </div>
                      </span>
                    </div>
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 2 ? "active-accordion" : "")
                      }
                    >
                      {" "}
                      <div
                        id="collapseOne"
                        className={"panel-collapse collapse " + (this.state.view === 2 ? "show" : "")}
                      >
                        <div className="panel-body text-white pl-4">{t("text16")}</div>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="panel cursorhand">
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 3 ? "active-accordion" : "")
                      }
                    >
                      <span className="accordion-toggle ml-0" onClick={() => this.setState({ view: 3 })}>
                        <div className="panel-title">
                          <span className="alt-font text-white d-inline-block font-weight-700">{t("title120")}</span>
                          <i className="indicator fas fa-angle-down text-white icon-extra-small"></i>
                        </div>
                      </span>
                    </div>
                    <div
                      id="collapseThree"
                      className={"panel-collapse collapse " + (this.state.view === 3 ? "show" : "")}
                      data-bs-parent="#accordion1"
                    >
                      <div className="panel-body text-white pl-4">{t("text17")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation("general")(JoinTeam);
