import React, { Component } from "react";
import People from "../sections/people";
import Line from "../sections/line";
import JoinTeam from "../sections/joinTeam";
import Jobs from "../sections/jobs";
import UTAlert from "../sections/utAlert";
import { withTranslation } from "react-i18next";
import LoginModal from "./loginmodal";
import $ from 'jquery';

class RawD3 extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0, showLogin: false };
    this.sectionRef = React.createRef();
    const baseUrl = process.env.PUBLIC_URL+"/images/brands/clearpea/sequence/liquid"

    this.images = [...Array(500)].map((_, i) => baseUrl + (i + 1).toString().padStart(3, '0') + `.webp`); // Pfad zu deinen Bildern
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);}


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const section = this.sectionRef.current;
    const sectionRect = section.getBoundingClientRect();
    const viewportHeight = window.innerHeight;


    if (sectionRect.top <= viewportHeight && sectionRect.bottom >= 0) {

      const sectionStart = sectionRect.top - viewportHeight;
      const sectionEnd = sectionRect.bottom;
      const visibleRange = sectionEnd - sectionStart;


      const scrollProgress = viewportHeight - sectionRect.top;
      const fraction = Math.max(0, Math.min(scrollProgress / visibleRange, 1));


      const index = Math.floor(fraction * (this.images.length - 1));


      if (index !== this.state.currentIndex) {
        this.setState({ currentIndex: index });
      }
    }
  };


  render() {
    const { t } = this.props;
    const bgIceTea = process.env.PUBLIC_URL+"/images/brands/clearpea/icetea.jpg"
    const bgCherry = process.env.PUBLIC_URL+"/images/brands/clearpea/cherry.jpg"
    const bgOrange = process.env.PUBLIC_URL+"/images/brands/clearpea/orange.jpg"
    const bgTropical = process.env.PUBLIC_URL+"/images/brands/clearpea/tropical.jpg"
    const bgGreenApple = process.env.PUBLIC_URL+"/images/brands/clearpea/greenapple.jpg"
    return (

      <React.Fragment>
        <LoginModal show={this.state.showLogin} close={() => this.setState({ showLogin: false })} />
        <section className="bg-extra-dark-gray-2 pb-0" style={{ background: "black" }}>
          <div className="container mt-15">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">
                <img src={`${process.env.PUBLIC_URL}/images/brands/rawd3_light.png`} className="mt-20"

                     style={{ maxHeight: 100, width: "auto", maxWidth: "50vw" }} />
                <h4
                  className="alt-font-3 font-weight-600 text-white w-80 mb-4 text-center"
                  style={{ marginLeft: "10%" }}
                >
                  Purely Pea, Perfectly Clear. RAWD3
                </h4>

              </div>
            </div>
          </div>
        </section>
        <video
          src={`${process.env.PUBLIC_URL}/images/brands/rawd3/bg3.mov`}
          style={{ height: "auto", width: "100vw", marginTop: -440 }}
          autoPlay
          loop
          muted
          playsInline
        >
          {t("title228")}
        </video>
        <section style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-8 ">
                <div className="cont"><h2
                  className="alt-font font-weight-600 text-white w-95 line-height-60px "><span className="color-font">Oil? Powder? Vegan?<br />Your Decision.</span>
                </h2><h5
                  className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-75"><img
                  src={`${process.env.PUBLIC_URL}/images/brands/rawd3_light.png`}

                  style={{ height: 24, width: "auto", marginLeft: 2 }} /> is our advanced, pure and highly absorbable
                  Vitamin D3, offered in various configurations. Decide between synthetic Vitamin D3 from lanolin and
                  vegan, cruelty-free Vitamin D3, available as powder, solved in sunflower oil or MCT oil for even
                  better performance.<br /><br />{t("title337")}
                  <br /><u onClick={() => {
                    this.setState({ showLogin: true });
                  }} className="cursor-pointer">{t("title338")}</u></h5></div>
              </div>
              <div className="col-12 col-xl-4 ">
                <div className="row ml-2">
                  <video
                    src={`${process.env.PUBLIC_URL}/images/brands/rawd3/radio2.mp4`}
                    style={{ height: "auto", width: "80%", marginTop: 40 }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >

                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container mt-15">
            <div className="row align-items-center justify-content-center mt-15">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft text-center" data-wow-delay="0.5s">


                <h4
                  className="alt-font-3 font-weight-600 text-white w-80 mb-4 text-center"
                  style={{ marginLeft: "10%" }}
                >
                  By using MCT oil to incorporate our Vitamin D3 we are able to significantly reduce the sensitization
                  rate.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div ref={this.sectionRef} style={{ height: "100vh", position: "relative" }}>
            <video

              src={`${process.env.PUBLIC_URL}/images/brands/rawcaps/machine_compressed.mp4`}
              style={{ height: "auto", width: "100vw" }}
              autoPlay
              muted
              playsInline
              loop

            >
              {this.props.t("title228")}
            </video>
            {/* Text-Container hinzufügen */}
            <div className="centered-container">

              <div className="row">
                <div className="col-12">

                  <h2
                    className="alt-font font-weight-600 text-white w-95 line-height-60px ">High-standard HPMC capsules,
                    trusted by manufacturers for quality and value.</h2>
                  <h5
                    className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-75">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/brands/rawcaps_light.png`}
                      style={{ height: 15, width: "auto", marginTop: 0 }} /> offers vegan, non-GMO, and allergen-free
                    HPMC capsules, perfect for dietary supplement production. Rigorously tested on Sejong, NJP, and
                    Bosch machines, they ensure consistent quality and reliability. Made from hydroxypropyl
                    methylcellulose, they meet the highest industry standards.</h5><b
                  className="text-white"><i
                  className="fa fa-check text-white mr-1"></i> <span style={{ fontSize: "1.2rem" }}>Allergen-Free</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Vegan</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Non-GMO</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Halal</span><i
                  className="fa fa-check text-white ml-4 mr-1"></i> <span
                  style={{ fontSize: "1.2rem" }}>Kosher</span></b></div>


              </div>

            </div>
          </div>
        </section>
        <section style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row">

              <div className="col-12 col-xl-6 ">
                <div className="row">
                  <video
                    src={`${process.env.PUBLIC_URL}/images/brands/rawd3/radio2.mp4`}
                    style={{ height: "auto", width: "80%", marginTop: 40 }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >

                  </video>
                </div>
              </div>
              <div className="col-12 col-xl-6 ">
                <div><h2
                  className="alt-font font-weight-600 text-white w-95 line-height-60px "><span className="color-font">Oil? Powder? Vegan?<br />Your Decision.</span>
                </h2><h5
                  className="mt-5 text-extra-large-2 text-white font-weight-500 margin-5px-bottom d-block w-100"><img
                  src={`${process.env.PUBLIC_URL}/images/brands/rawd3_light.png`}

                  style={{ height: 24, width: "auto", marginLeft: 2 }} /> isddd our advanced, pure and highly absorbable
                  Vitamin D3, offered in various configurations. Decide between synthetic Vitamin D3 from lanolin and
                  vegan, cruelty-free Vitamin D3, available as powder, solved in sunflower oil or MCT oil for even
                  better performance.<br /><br />{t("title337")}
                  <br /><u onClick={() => {
                    this.setState({ showLogin: true });
                  }} className="cursor-pointer">{t("title338")}</u></h5></div>
              </div>
            </div>
          </div>
        </section>



        <section className="bg-extra-dark-gray-2 pt-0" style={{ backgroundColor: "rgb(2,2,2)" }}>
          <div className="container">
            <div className="overlap-section card specification-card py-0">
              <div
                className="border-radius-6px padding-4-half-rem-tb padding-4-rem-lr sm-no-padding-lr wow animate__pulse"
              >
                <div className="row align-items-center justify-content-center">
                  <div className="col-10 col-xl-8 text-xl-start lg-margin-40px-bottom text-center text-xl-left"><span
                    className="text-extra-medium alt-font text-white">{t("title343")}</span>
                    <h6 className="alt-font font-weight-600 text-white letter-spacing-minus-1-half m-0">{t("title344")}
                      <img
                        src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                        style={{ height: 24, width: "auto", marginLeft: 2 }} /> {t("title345")}</h6></div>
                  <div className="col-22 col-lg-3 text-center">
                    <a className="btn btn-medium btn-neon-orange btn-fancy btn-round-edge section-link"
                       href={`mailto:info@rawbids.com?subject=Sample Request - ClearPEA&body=${encodeURIComponent(`Sehr geehrte Damen und Herren,

bitte schicken Sie mir ein Sample von ClearPEA an folgende Adresse:

[HIER ADRESSE ANGEBEN]

Mit freundlichen Grüßen,

[IHR NAME]`)}`}>{t("title346")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-extra-dark-gray-2" style={{ background: "rgb(2, 2, 2)" }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-10 col-md-10  wow animate__fadeInLeft" data-wow-delay="0.5s">


                <h4
                  className="alt-font-3 font-weight-600 text-white w-100 mb-4"

                >
                  {t("title347")}
                </h4>

                {/* ------------------------------ */}
                <div className="d-flex flex-column specification-header px-0 mx-0">
                  <div className="d-flex align-items-center justify-content-between my-3 w-100"
                       style={{ marginLeft: 25, lineHeight: 2 }}>
                    <div className="d-flex flex-column">
                      <span className="h3 text-white font-weight-700 mb-0 pb-0"><img
                        src={`${process.env.PUBLIC_URL}/images/brands/clearpea_light.png`}

                        style={{ height: 24, width: "auto", marginLeft: 2 }} /></span>
                      <div className="font-size-lg text-gray-3">85% Protein, 80% Peptides; Pisum sativum Linn; Seed
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card specification-card">


                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Basic Information</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">CAS Number</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">n/a</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Origin Country</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">China</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Article Number</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">14502</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Organic</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Category</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Protein</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">HS Code</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">3504 0090 90 0</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Composition</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Powder</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Extraction Solvent</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Enzymes, Acids, Water, Ethanol
                      </div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Color</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">transparent</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Density</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">350 - 650 kg/m3</div>
                    </div>
                  </div>
                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Physical & Chemical Parameters</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">Appearance</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">White, Yellowish to Light Cream
                      </div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Ash</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 8%</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Limits</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">-</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Loss on Drying</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 8%</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Odor</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">Characteristic</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Particle Size</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">95% through 100 mesh</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Purity</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">>85%</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Specific Rotation</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">-
                      </div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">pH</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">5</div>

                    </div>
                  </div>
                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Labels & Standards</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">CITES</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">ECHA</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Halal</div>
                      <div className="col-3 text-success-2 font-weight-500 font-size-lg">Yes</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Hazard Material</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Kosher</div>
                      <div className="col-3 text-success-2 font-weight-500 font-size-lg">Yes</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Novel Food</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Packaging</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">N/A</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Vegan</div>
                      <div className="col-3 text-success-2 font-weight-500 font-size-lg">Yes</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Vegetarian</div>
                      <div className="col-3 text-success-2 font-weight-500 font-size-lg">Yes</div>


                    </div>
                  </div>


                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Grade</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">Cosmetic Grade</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Feed Grade</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Food Grade</div>
                      <div className="col-3 text-success-2 font-weight-500 font-size-lg">Yes</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Industrial Grade</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Medicine Grade</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Pharmaceutical Grade</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">USP Grade</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">No</div>


                    </div>
                  </div>

                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Contaminants</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">Aflatoxins</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">&lt; 10 ppb</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Benzopyrene</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 10 ppb</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">Max. Allowed ETO</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">≤ 0.1 ppm</div>
                      <div className="col-3 text-white font-weight-500 font-size-lg">PAH 4</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">
                        ≤ 50 ppb
                      </div>


                    </div>
                  </div>


                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Active Substances</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">Protein</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">85%</div>


                    </div>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">Peptides</div>
                      <div className="col-3 text-gray-3 font-weight-500 font-size-lg">80%</div>


                    </div>
                  </div>

                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Allergens</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <div className="col-3 text-white font-weight-500 font-size-lg">
                        <div className="badge badge-dark p-1 px-2">Pea</div>
                      </div>


                    </div>
                  </div>
                  <div className="border-bottom-dark-gray" />
                  <div className="row">
                    <div className="col-12 text-white h4 mb-4">Specification</div>
                  </div>
                  <div className="container" style={{ lineHeight: 1.9 }}>
                    <div className="row">
                      <a style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75px",

                      }} href={`${process.env.PUBLIC_URL}/pdf/ClearPEA-Green_Apple.pdf`} target="_blank">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                          alt="PDF Icon"
                          style={{ width: 24, marginBottom: 5 }}

                        />
                        <div style={{ fontSize: ".9rem", color: "white" }}>Green Apple</div>
                      </a>
                      <a style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75px"

                      }}
                         href={`${process.env.PUBLIC_URL}/pdf/ClearPEA-Cherry.pdf`} target="_blank">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                          alt="PDF Icon"
                          style={{ width: 24, marginBottom: 5 }}
                        />
                        <div style={{ fontSize: ".9rem", color: "white" }}>Cherry</div>
                      </a>
                      <a style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75px"
                      }}
                         href={`${process.env.PUBLIC_URL}/pdf/ClearPEA-Orange.pdf`} target="_blank">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                          alt="PDF Icon"
                          style={{ width: 24, marginBottom: 5 }}
                        />
                        <div style={{ fontSize: ".9rem", color: "white" }}>Orange</div>
                      </a>
                      <a style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75px"
                      }}
                         href={`${process.env.PUBLIC_URL}/pdf/ClearPEA-IceTea_Peach.pdf`} target="_blank">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                          alt="PDF Icon"
                          style={{ width: 24, marginBottom: 5 }}
                        />
                        <div style={{ fontSize: ".9rem", color: "white" }}>Ice Tea Peach</div>
                      </a>
                      <a style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75px"
                      }}
                         href={`${process.env.PUBLIC_URL}/pdf/ClearPEA-Tropical.pdf`} target="_blank">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pdf.svg`}
                          alt="PDF Icon"
                          style={{ width: 24, marginBottom: 5 }}
                        />
                        <div style={{ fontSize: ".9rem", color: "white" }}>Tropical</div>
                      </a>


                    </div>
                  </div>

                </div>


                {/* ------------------------------ */}


              </div>


              <div className="w-100 d-block text-center mt-10"><sup style={{ fontSize: 12 }}>*</sup>{t("title348")}
              </div>


            </div>

          </div>
        </section>


        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(RawD3);
